import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { _t } from '@hip/translations';
import { getCategory } from '../core/utils';
import { RouteList } from '../routes';
import { AppointmentErrorType } from '../core/appointment';
export var useHeaderTranslations = function (options) {
    var category = useSelector(getCategory);
    var path = useRouteMatch().path;
    switch (path) {
        case RouteList.CATEGORY:
            return {
                title: _t("appointment_booking.steps.category.header." + category + ".title"),
                subtitle: _t("appointment_booking.steps.category.header." + category + ".subtitle"),
            };
        case RouteList.STORE:
            return {
                title: _t("appointment_booking.steps.store.header." + category + ".title"),
                subtitle: _t("appointment_booking.steps.store.header." + category + ".subtitle"),
            };
        case RouteList.DATETIME:
            return {
                title: _t("appointment_booking.steps.datetime.header." + category + ".title"),
                subtitle: _t("appointment_booking.steps.datetime.header." + category + ".subtitle", { name: options === null || options === void 0 ? void 0 : options.storeName, phone: options === null || options === void 0 ? void 0 : options.storePhone }),
            };
        case RouteList.CONTACTS:
            return {
                title: _t("appointment_booking.steps.contacts.header." + category + ".title"),
                subtitle: _t("appointment_booking.steps.contacts.header." + category + ".subtitle", { name: options === null || options === void 0 ? void 0 : options.storeName, phone: options === null || options === void 0 ? void 0 : options.storePhone }),
            };
        case RouteList.CONFIRMATION + "/:appointmentId":
            return {
                title: (options === null || options === void 0 ? void 0 : options.clientName) ? _t("appointment_booking.steps.confirmation.header." + category + ".title", { name: options === null || options === void 0 ? void 0 : options.clientName })
                    : _t("appointment_booking.steps.confirmation.header." + category + ".titleWithoutName"),
                subtitle: _t("appointment_booking.steps.confirmation.header." + category + ".subtitle"),
            };
        case RouteList.AMEND_CALENDAR + "/:appointmentId":
        case RouteList.AMEND + "/:appointmentId":
            if ((options === null || options === void 0 ? void 0 : options.appointmentErrorType) ===
                AppointmentErrorType.appointmentDoNotExist) {
                return {
                    title: _t("appointment_booking.steps.amend.header." + category + ".title.not_existing"),
                    subtitle: null,
                };
            }
            if ((options === null || options === void 0 ? void 0 : options.appointmentErrorType) ===
                AppointmentErrorType.appointmentWasCanceled) {
                return {
                    title: _t("appointment_booking.steps.amend.header." + category + ".title.canceled"),
                    subtitle: _t("appointment_booking.steps.amend.header." + category + ".subtitle.canceled"),
                };
            }
            return {
                title: _t("appointment_booking.steps.amend.header." + category + ".title.regular"),
                subtitle: _t("appointment_booking.steps.amend.header." + category + ".subtitle.regular"),
            };
        case RouteList.AMEND + "/:appointmentId" + RouteList.SUCCESS: {
            return {
                title: _t("appointment_booking.steps.amend_success.header." + category + ".title"),
                subtitle: _t("appointment_booking.steps.amend_success.header." + category + ".subtitle"),
            };
        }
        default: {
            return {
                title: null,
                subtitle: null,
            };
        }
    }
};
