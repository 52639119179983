import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Toast } from '@realm-kf/halcyon-components-react';
import { _t } from '@hip/translations';
import { Layout } from '../../components/layout';
import { actions as analyticsActions, AnalyticsEventMap, AnalyticsEvents, makeAnalyticsPayload, } from '../../core/analytics';
import { getBooking, getClient, getError, } from '../../core/appointment/selectors';
import { AppointmentSummary } from '../../components/appointmentSummary/appointmentSummary';
import { useHeaderTranslations } from '../../hooks/useHeaderTranslations';
var ConfirmationStep = function () {
    var dispatch = useDispatch();
    var appointmentId = useParams().appointmentId;
    var booking = useSelector(getBooking);
    var client = useSelector(getClient);
    var error = useSelector(getError);
    useEffect(function () {
        if (booking) {
            dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.CONFIRMATION, (booking === null || booking === void 0 ? void 0 : booking.service_name) + " " + (booking === null || booking === void 0 ? void 0 : booking.booking_type))));
        }
    }, [booking]);
    useEffect(function () {
        if (error) {
            dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.GENERIC_ERROR, AnalyticsEventMap[AnalyticsEvents.CONFIRMATION].action)));
        }
    }, [error]);
    var _a = useHeaderTranslations({
        clientName: client === null || client === void 0 ? void 0 : client.first_name,
    }), title = _a.title, subtitle = _a.subtitle;
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: title, subtitle: subtitle },
            React.createElement(AppointmentSummary, { appointmentId: appointmentId, isPrintable: true })),
        React.createElement(Toast, { content: _t('appointment_booking.common.sth_went_wrong'), fromTop: "0", isVisible: !!error, variant: "warning" })));
};
export { ConfirmationStep };
