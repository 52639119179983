import dayjs from 'dayjs';
export var createListOfDaysOfWeek = function () {
    var daysOfWeek = [];
    for (var i = 0; i < 7; i++) {
        var dayOfWeek = dayjs().startOf('week').add(i, 'day').format('dd');
        daysOfWeek.push(dayOfWeek);
    }
    return daysOfWeek;
};
export var createListOfDaysOfMonth = function (defaultDate) {
    var startOfMonth = defaultDate.startOf('month');
    var endOfMonth = defaultDate.endOf('month');
    var dates = [];
    for (var i = startOfMonth; i.isBefore(endOfMonth); i = i.add(1, 'day')) {
        dates.push(i);
    }
    return dates;
};
