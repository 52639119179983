import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Loading } from '@realm-kf/halcyon-components-react';
import { useAnalytics } from '../hooks';
import { actions as analyticsActions } from '../core/analytics';
import { actions as utilsActions, getIsAppReady, JrniServiceCategory, getAnalyticsConfig, } from '../core/utils';
var CATEGORY_QUERY_PARAMETER = 'category';
var App = function (_a) {
    var children = _a.children;
    var dispatch = useDispatch();
    var location = useLocation();
    var isAppReady = useSelector(getIsAppReady);
    var analyticsConfig = useSelector(getAnalyticsConfig);
    var onLoad = function () { return dispatch(analyticsActions.initAnalytics()); };
    useAnalytics(analyticsConfig, onLoad);
    useEffect(function () {
        var query = new URLSearchParams(location.search);
        var category;
        switch (query.get(CATEGORY_QUERY_PARAMETER)) {
            case JrniServiceCategory.Online:
                category = JrniServiceCategory.Online;
                break;
            case JrniServiceCategory.InStore:
                category = JrniServiceCategory.InStore;
                break;
            case JrniServiceCategory.EnergyAssessment:
                category = JrniServiceCategory.EnergyAssessment;
                break;
            case JrniServiceCategory.EnergyAssessmentInStore:
                category = JrniServiceCategory.EnergyAssessmentInStore;
                break;
            case JrniServiceCategory.GardenDesignInstore:
                category = JrniServiceCategory.GardenDesignInstore;
                break;
            case JrniServiceCategory.WindowsAndDoors:
                category = JrniServiceCategory.WindowsAndDoors;
                break;
            case JrniServiceCategory.Carpets:
                category = JrniServiceCategory.Carpets;
                break;
            default:
                category = JrniServiceCategory.InStore;
        }
        dispatch(utilsActions.setCategory(category));
        var script = document.createElement('script');
        script.src =
            'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.contentWindow.min.js';
        script.async = true;
        document.body.appendChild(script);
        return function () {
            document.body.removeChild(script);
        };
    }, []);
    useEffect(function () {
        dispatch(utilsActions.init());
    }, []);
    return React.createElement(React.Fragment, null, isAppReady ? children : React.createElement(Loading, { show: true }));
};
export { App };
