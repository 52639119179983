import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Text } from '@realm-kf/halcyon-components-react';
import { dataTestId } from '@hip/test-attributes';
import { Cell } from '../cell';
import { Legend } from '../legend';
import { createListOfDaysOfWeek } from '../utils';
import styles from './board.css';
var Board = React.memo(function (_a) {
    var dates = _a.dates, maxDate = _a.maxDate, minDate = _a.minDate, preselectedDate = _a.preselectedDate, unavailableDates = _a.unavailableDates, onSelectDate = _a.onSelectDate;
    var _b = useState(null), selectedDate = _b[0], setSelectedDate = _b[1];
    useEffect(function () {
        setSelectedDate(dayjs(preselectedDate, 'YYYY-MM-DD'));
    }, [preselectedDate]);
    var handleSelectDate = useCallback(function (dayjsDate) {
        setSelectedDate(dayjsDate);
        var date = dayjsDate.format('YYYY-MM-DD');
        onSelectDate && onSelectDate(date);
    }, []);
    var daysOfWeek = createListOfDaysOfWeek();
    return (React.createElement(React.Fragment, null,
        React.createElement("ul", { className: styles.DaysOfWeekWrapper }, daysOfWeek.map(function (item) { return (React.createElement(Text, { key: item, classes: "hlcn-u-pb-small", align: "center", as: "li", size: "largeResponsive", weight: "bold" }, item)); })),
        React.createElement("ul", { className: styles.DatesWrapper }, dates.map(function (item, i) {
            var disabled = unavailableDates.includes(item.format('YYYY-MM-DD')) ||
                item.isBefore(minDate) ||
                item.isAfter(maxDate);
            var isToday = item.isSame(dayjs(), 'date');
            return (React.createElement(Cell, { dataTestId: isToday
                    ? dataTestId.ab.calendar.dayCellToday
                    : dataTestId.ab.calendar.dayCell, key: item.format(), date: item, disabled: disabled, isSelected: item.isSame(selectedDate, 'date'), isToday: isToday, startColumn: !i ? item.weekday() + 1 : undefined, onSelect: handleSelectDate }));
        })),
        React.createElement(Legend, null)));
});
export { Board };
