import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["CHECKOUT_BASKET"] = "@hip/appointment-booking/booking/CHECKOUT_BASKET";
    ActionTypes["CHECKOUT_BASKET_SUCCESS"] = "@hip/appointment-booking/booking/CHECKOUT_BASKET_SUCCESS";
    ActionTypes["CHECKOUT_BASKET_FAILURE"] = "@hip/appointment-booking/booking/CHECKOUT_BASKET_FAILURE";
    ActionTypes["SET_ERROR"] = "@hip/appointment-booking/booking/SET_ERROR";
    ActionTypes["CLEAR_ERROR"] = "@hip/appointment-booking/booking/CLEAR_ERROR";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    checkoutBasket: function (payload) {
        return createAction(ActionTypes.CHECKOUT_BASKET, payload);
    },
    checkoutBasketSuccess: function (data) {
        return createAction(ActionTypes.CHECKOUT_BASKET_SUCCESS, { data: data });
    },
    checkoutBasketFailure: function (error) {
        return createAction(ActionTypes.CHECKOUT_BASKET_FAILURE, { error: error });
    },
    setError: function (error) { return createAction(ActionTypes.SET_ERROR, { error: error }); },
    clearError: function () { return createAction(ActionTypes.CLEAR_ERROR); },
};
