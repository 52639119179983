var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import classnames from 'classnames';
import { List, Text, Link } from '@realm-kf/halcyon-components-react';
import { dataTestId } from '@hip/test-attributes';
import { _t } from '@hip/translations';
import { JrniServiceCategory } from '../../core/utils';
var LineItem = function (_a) {
    var title = _a.title, children = _a.children, dataTestId = _a.dataTestId;
    return (React.createElement("div", { className: classnames('hlcn-u-display-block', 'hlcn-u-pt-medium', 'hlcn-u-pl-large'), "data-testid": dataTestId },
        React.createElement(Text, { block: true, weight: "bold", size: "large" }, title),
        children));
};
var SummaryList = function (_a) {
    var appointmentSummaryTexts = _a.appointmentSummaryTexts;
    var dateString = appointmentSummaryTexts.dateString, timeString = appointmentSummaryTexts.timeString, durationString = appointmentSummaryTexts.durationString, storeName = appointmentSummaryTexts.storeName, storeHref = appointmentSummaryTexts.storeHref, firstName = appointmentSummaryTexts.firstName, lastName = appointmentSummaryTexts.lastName, email = appointmentSummaryTexts.email, mobile_prefix = appointmentSummaryTexts.mobile_prefix, mobile = appointmentSummaryTexts.mobile, category = appointmentSummaryTexts.category;
    var listItems = __spreadArrays([
        {
            content: (React.createElement(LineItem, { title: _t('appointment_booking.appointment_summary.fields.date_and_time') },
                React.createElement(Text, { block: true, "data-testid": dataTestId.ab.confirmationDetails.mobile.appointmentDate }, dateString),
                React.createElement(Text, { block: true, "data-testid": dataTestId.ab.confirmationDetails.mobile.appointmentTime }, timeString))),
        },
        {
            content: (React.createElement(LineItem, { title: _t('appointment_booking.appointment_summary.fields.duration') },
                React.createElement(Text, { block: true, "data-testid": dataTestId.ab.confirmationDetails.mobile.appointmentDuration }, durationString))),
        }
    ], (category !== JrniServiceCategory.EnergyAssessment
        ? [
            {
                content: (React.createElement(LineItem, { title: _t('appointment_booking.appointment_summary.fields.location') },
                    React.createElement(Text, { block: true, space: "xsmall", "data-testid": dataTestId.ab.confirmationDetails.selectedStore }, storeName),
                    category !== JrniServiceCategory.Online && (React.createElement(Link, { "data-testid": dataTestId.ab.confirmationDetails.selectedStoreLink, href: storeHref, linkUnderline: true, inNewTab: true }, _t('appointment_booking.appointment_summary.full_store_info_and_facilities'))))),
            },
        ]
        : []), [
        {
            content: (React.createElement(LineItem, { title: _t('appointment_booking.appointment_summary.fields.your_details'), dataTestId: dataTestId.ab.confirmationDetails.mobile.customerPersonalData },
                React.createElement(Text, { block: true, "data-testid": dataTestId.ab.confirmationDetails.mobile.customerFirstLasName },
                    firstName,
                    " ",
                    lastName),
                React.createElement(Text, { block: true, "data-testid": dataTestId.ab.confirmationDetails.mobile.customerEmail }, email),
                React.createElement(Text, { block: true, "data-testid": dataTestId.ab.confirmationDetails.mobile.customerPhoneNumber },
                    "+",
                    mobile_prefix,
                    mobile))),
        },
    ]);
    return (React.createElement("div", { "data-testid": dataTestId.ab.confirmationDetails.confirmationTableMobile, className: classnames('hlcn-u-pt-small', 'hlcn-u-pb-large', 'hlcn-u-bgc-light') },
        React.createElement(List, { items: listItems, classes: 'hlcn-u-bgc-light' })));
};
export { SummaryList };
