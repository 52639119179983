import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from '@redux-devtools/extension';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { isProd } from '@hip/helpers';
import { rootSaga } from './sagas';
import { createRootReducers } from './reducer';
var sagaMiddleware = createSagaMiddleware();
export var history = createBrowserHistory();
export var store = createStore(createRootReducers(history), isProd()
    ? applyMiddleware(routerMiddleware(history), sagaMiddleware)
    : composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware)));
sagaMiddleware.run(rootSaga);
