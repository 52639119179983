import React from 'react';
import classnames from 'classnames';
import { IconFindStore } from '@realm-kf/halcyon-components-react';
import { dataTestId } from '@hip/test-attributes';
import styles from './menuItem.css';
var MenuItem = function (_a) {
    var _b;
    var active = _a.active, option = _a.option, onMouseDown = _a.onMouseDown, onKeyDown = _a.onKeyDown;
    var label = option.label, sub = option.sub, id = option.id;
    return (React.createElement("li", { "data-testid": dataTestId.ab.storeDetails.locationSuggestion, className: classnames(styles.MenuItem, (_b = {},
            _b[styles.MenuItemActive] = active,
            _b)), "data-id": id, onMouseDown: function (e) { return onMouseDown(e, option); }, onKeyDown: onKeyDown },
        React.createElement(IconFindStore, { classes: styles.MenuItemIcon, size: 16 }),
        React.createElement("span", { className: styles.MenuItemLabel },
            label,
            ","),
        sub && React.createElement("span", { className: styles.MenuItemSub }, sub)));
};
export { MenuItem };
