import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { dataTestId } from '@hip/test-attributes';
import { cleanupWoosMapScript, initWoosMapScript, mapInitOrUpdateHandler, } from '../../../components/woosMap/woosMap';
import { getActiveStore } from '../../../core/location/stores';
import { getWoosmapApiKey } from '../../../core/utils';
import styles from './mapOfStores.css';
var MapOfStores = function () {
    var _a = useState(false), woosmapScriptLoaded = _a[0], setWoosmapScriptLoaded = _a[1];
    var mapRef = useRef(null);
    var markerRef = useRef(null);
    var infoWindowRef = useRef(null);
    var MAP_ELEMENT_ID = 'map';
    var mapMountPoint = document.getElementById(MAP_ELEMENT_ID);
    var woosmapAPIKey = useSelector(getWoosmapApiKey);
    var activeStore = useSelector(getActiveStore);
    useEffect(function () {
        if (woosmapAPIKey) {
            initWoosMapScript(woosmapAPIKey, function () { return setWoosmapScriptLoaded(true); });
        }
        return function () { return cleanupWoosMapScript(); };
    }, [woosmapAPIKey]);
    useEffect(function () {
        var isMapAlreadyLoaded = !!mapRef.current;
        var isMapReadyToLoad = activeStore && woosmapScriptLoaded;
        mapInitOrUpdateHandler(isMapReadyToLoad, isMapAlreadyLoaded, mapRef, markerRef, infoWindowRef, activeStore, mapMountPoint);
    }, [activeStore, woosmapScriptLoaded]);
    return (React.createElement("div", { id: MAP_ELEMENT_ID, className: styles.Map, "data-testid": dataTestId.ab.storeDetails.mapWidget }));
};
export { MapOfStores };
