import React from 'react';
import { Container } from '@realm-kf/halcyon-components-react';
import { Header } from '../header/header';
import { Footer } from '../footer/footer';
import styles from './layout.css';
var Layout = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, children = _a.children, _b = _a.footerProps, footerProps = _b === void 0 ? {} : _b;
    var primaryLabel = footerProps.primaryLabel, primaryDisabled = footerProps.primaryDisabled, primaryClick = footerProps.primaryClick, secondaryLabel = footerProps.secondaryLabel, secondaryClick = footerProps.secondaryClick, secondaryVariant = footerProps.secondaryVariant;
    var showFooter = primaryClick || secondaryClick;
    return (React.createElement(Container, { classes: styles.Layout },
        React.createElement(Header, { title: title, subtitle: subtitle }),
        React.createElement("main", { className: styles.Content }, children),
        showFooter && (React.createElement(Footer, { primaryLabel: primaryLabel, primaryDisabled: primaryDisabled, primaryClick: primaryClick, secondaryLabel: secondaryLabel, secondaryClick: secondaryClick, secondaryVariant: secondaryVariant }))));
};
export { Layout };
