import React from 'react';
import { dataTestId } from '@hip/test-attributes';
import { Button } from '../button/button';
import styles from './footer.css';
var Footer = function (_a) {
    var primaryLabel = _a.primaryLabel, primaryDisabled = _a.primaryDisabled, primaryClick = _a.primaryClick, secondaryLabel = _a.secondaryLabel, secondaryClick = _a.secondaryClick, _b = _a.secondaryVariant, secondaryVariant = _b === void 0 ? 'secondary-dark' : _b;
    var showPrimary = !!primaryClick;
    var showSecondary = !!secondaryClick;
    return (React.createElement("footer", { className: styles.Footer, "data-testid": dataTestId.ab.footer.footerElement },
        showSecondary && (React.createElement(Button, { dataTestId: dataTestId.ab.footer.backButton, classes: styles.SecondaryButton, variant: secondaryVariant, onClick: secondaryClick }, secondaryLabel)),
        showPrimary && (React.createElement(Button, { dataTestId: dataTestId.ab.footer.continueButton, classes: styles.PrimaryButton, disabled: primaryDisabled, onClick: primaryClick }, primaryLabel))));
};
export { Footer };
