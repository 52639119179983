var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionTypes } from './actions';
export var initialState = {
    storeId: null,
    serviceId: null,
    maxAdvancePeriod: null,
    error: null,
    isLoading: false,
    minAdvancePeriod: null,
};
export var selectedStore = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.LOAD_SERVICE_ID:
            return __assign(__assign({}, state), { serviceId: null, storeId: action.payload.selectedStoreJrniId, isLoading: true, error: null });
        case ActionTypes.LOAD_SERVICE_ID_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, serviceId: action.payload.serviceId, minAdvancePeriod: action.payload.minAdvancePeriod, maxAdvancePeriod: action.payload.maxAdvancePeriod });
        case ActionTypes.LOAD_SERVICE_ID_FAILURE:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload });
        case ActionTypes.REMOVE_SERVICE_ID_ERROR:
            return __assign(__assign({}, state), { error: null });
        default:
            return state;
    }
};
