import dayjs from 'dayjs';
import { createStoreHref } from './url';
export var formatAppointmentSummaryTexts = function (appointmentSummary) {
    var hostUrl = appointmentSummary.hostUrl, booking = appointmentSummary.booking, client = appointmentSummary.client, store = appointmentSummary.store;
    if (!(booking && client))
        return null;
    var firstName = client.first_name, lastName = client.last_name, email = client.email, mobile = client.mobile, mobile_prefix = client.mobile_prefix;
    var datetime = booking.datetime, duration = booking.duration, category = booking.category;
    var serviceName = booking.service_name;
    var storeHref = createStoreHref(hostUrl, store === null || store === void 0 ? void 0 : store.ref);
    var dateString = dayjs(datetime).format('dddd D MMMM');
    var timeString = dayjs(datetime).format('h:mm A');
    var durationString = dayjs.duration(duration, 'minutes').humanize();
    var storeName = store === null || store === void 0 ? void 0 : store.name;
    return {
        dateString: dateString,
        timeString: timeString,
        durationString: durationString,
        storeName: storeName,
        storeHref: storeHref,
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobile_prefix: mobile_prefix,
        mobile: mobile,
        serviceName: serviceName,
        category: category,
    };
};
