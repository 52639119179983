/**
 * Dictionary below translates language detected by i18n to a name of localisation for DayJs library
 * If you need to add a new locale, please find the appropriate localisation on the list by the link below
 * https://github.com/iamkun/dayjs/tree/dev/src/locale
 */
var localesDictionary = {
    'en-GB': 'en-gb',
    'ro-RO': 'ro',
};
export var detectedLanguageToDayJsLocale = function (detectedLanguage) {
    var _a;
    return (_a = localesDictionary[detectedLanguage]) !== null && _a !== void 0 ? _a : 'en';
};
