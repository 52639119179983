import { _t } from '@hip/translations';
import { stringifyDistance } from '@hip/helpers';
export var processStores = function (storesResponse) {
    var _a;
    return ((_a = storesResponse === null || storesResponse === void 0 ? void 0 : storesResponse._embedded) === null || _a === void 0 ? void 0 : _a.companies.map(function (item) {
        var _a;
        return ({
            distanceFromUser: null,
            jrniId: item.id,
            name: item.name,
            sapStoreId: item.ref,
            address: processAddress(item.address),
            virtualHub: (_a = item === null || item === void 0 ? void 0 : item.extra) === null || _a === void 0 ? void 0 : _a.virtual_hub,
        });
    })) || [];
};
export var processVirtualHubStore = function (virtualHub) {
    var _a;
    return ({
        distanceFromUser: null,
        jrniId: virtualHub.id,
        name: virtualHub.name,
        sapStoreId: virtualHub.ref,
        address: null,
        virtualHub: (_a = virtualHub === null || virtualHub === void 0 ? void 0 : virtualHub.extra) === null || _a === void 0 ? void 0 : _a.virtual_hub,
    });
};
export var processAddress = function (addressResponse) {
    return addressResponse && {
        lat: addressResponse.lat,
        lng: addressResponse.long,
        address1: addressResponse.address1,
        address2: addressResponse.address2,
        address3: addressResponse.address3,
        address4: addressResponse.address4,
        address5: addressResponse.address5,
        country: addressResponse.country,
        mapMarker: addressResponse.map_marker,
        postcode: addressResponse.postcode,
        phone: addressResponse.phone,
    };
};
export var processOpeningHours = function (openingHoursResponse) {
    var _a;
    return ((_a = openingHoursResponse === null || openingHoursResponse === void 0 ? void 0 : openingHoursResponse.openingHoursSpecification) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
        closes: item.closes,
        dayOfWeek: item.dayOfWeek,
        opens: item.opens,
    }); })) || [];
};
export var formatDestinations = function (stores) {
    var storesWithoutAddressIndexes = [];
    var destinations = [];
    stores.forEach(function (store, i) {
        var _a, _b;
        if (!((_a = store === null || store === void 0 ? void 0 : store.address) === null || _a === void 0 ? void 0 : _a.lat) && !((_b = store === null || store === void 0 ? void 0 : store.address) === null || _b === void 0 ? void 0 : _b.lng)) {
            storesWithoutAddressIndexes.push(i);
            return;
        }
        var _c = store === null || store === void 0 ? void 0 : store.address, lat = _c.lat, lng = _c.lng;
        destinations.push(lat + "," + lng);
    });
    return { storesWithoutAddressIndexes: storesWithoutAddressIndexes, destinations: destinations.join('|') };
};
export var processStoresDistances = function (stores, distancesData) {
    var _a, _b;
    var distances = (_b = (_a = distancesData === null || distancesData === void 0 ? void 0 : distancesData.data) === null || _a === void 0 ? void 0 : _a.rows[0]) === null || _b === void 0 ? void 0 : _b.elements;
    var counter = 0;
    stores.forEach(function (_, i) {
        var _a;
        if (distances) {
            if (!distancesData.storesWithoutAddressIndexes.includes(i)) {
                var distanceValue = (_a = distances[i - counter]) === null || _a === void 0 ? void 0 : _a.distance.value;
                var distanceString = stringifyDistance(distanceValue);
                stores[i].distanceFromUser = {
                    value: distanceValue,
                    text: distanceString,
                };
            }
            else {
                counter++;
            }
        }
        else {
            var DISTANCE_LIMIT = 600;
            stores[i].distanceFromUser = {
                value: DISTANCE_LIMIT,
                text: _t('appointment_booking.steps.store.distance_more_than_x_miles', {
                    value: DISTANCE_LIMIT,
                }),
            };
        }
    });
    return stores.sort(function (a, b) {
        var aDistance = a.distanceFromUser.value;
        var bDistance = b.distanceFromUser.value;
        if (isNaN(aDistance) || aDistance > bDistance) {
            return 1;
        }
        else if (isNaN(bDistance) || aDistance < bDistance) {
            return -1;
        }
        else {
            return 0;
        }
    });
};
export var isClosestStoreHandledByHub = function (stores) { var _a; return stores && stores.length && ((_a = stores[0]) === null || _a === void 0 ? void 0 : _a.virtualHub) === 'A'; };
