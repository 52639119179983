import { createSelector } from 'reselect';
export var getRoot = function (state) {
    return state.checkout;
};
export var getIsCheckoutBasketLoading = createSelector(getRoot, function (_a) {
    var isLoading = _a.isLoading;
    return isLoading;
});
export var getCheckoutError = createSelector(getRoot, function (_a) {
    var error = _a.error;
    return error;
});
export var getCheckoutData = createSelector(getRoot, function (state) { return state === null || state === void 0 ? void 0 : state.data; });
export var getId = createSelector(getCheckoutData, function (data) { return data === null || data === void 0 ? void 0 : data.long_id; });
