import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["LOAD_CATEGORIES"] = "@hip/appointment-booking/categories/LOAD_CATEGORIES";
    ActionTypes["LOAD_CATEGORIES_SUCCESS"] = "@hip/appointment-booking/categories/LOAD_CATEGORIES_SUCCESS";
    ActionTypes["LOAD_CATEGORIES_FAILURE"] = "@hip/appointment-booking/categories/LOAD_CATEGORIES_FAILURE";
    ActionTypes["REMOVE_CATEGORIES_ERROR"] = "@hip/appointment-booking/categories/REMOVE_CATEGORIES_ERROR";
    ActionTypes["SELECT_CATEGORY"] = "@hip/appointment-booking/categories/SELECT_CATEGORY";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    loadCategories: function () { return createAction(ActionTypes.LOAD_CATEGORIES); },
    loadCategoriesSuccess: function (data) {
        return createAction(ActionTypes.LOAD_CATEGORIES_SUCCESS, { data: data });
    },
    loadCategoriesFailure: function (error) {
        return createAction(ActionTypes.LOAD_CATEGORIES_FAILURE, { error: error });
    },
    selectCategory: function (category) {
        return createAction(ActionTypes.SELECT_CATEGORY, { category: category });
    },
    removeCategoriesError: function () {
        return createAction(ActionTypes.REMOVE_CATEGORIES_ERROR);
    },
};
