import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Calendar } from '../../../components/calendar';
import { getIsServiceIdLoading, getMinAdvancePeriod, getMaxAdvancePeriod, getSelectedStoreServiceId, } from '../../../core/location/selectedStore';
import { actions, getDateAvailability, getIsDateAvailabilityLoading, getSelectedDateValue, getUnavailableDates, } from '../../../core/timeslot/dateAvailability';
import { actions as basketActions } from '../../../core/basket/actions';
import { actions as timeActions } from '../../../core/timeslot/timeAvailability/actions';
import { getBooking, getBookingDateSlot } from '../../../core/appointment';
var DateSelection = function () {
    var dispatch = useDispatch();
    var dateAvailability = useSelector(getDateAvailability);
    var unavailableDates = useSelector(getUnavailableDates);
    var isDateAvailabilityLoading = useSelector(getIsDateAvailabilityLoading);
    var isServiceIdLoading = useSelector(getIsServiceIdLoading);
    var selectedStoreServiceId = useSelector(getSelectedStoreServiceId);
    var selectedDateValue = useSelector(getSelectedDateValue);
    var maxAdvancePeriod = useSelector(getMaxAdvancePeriod);
    var minAdvancePeriod = useSelector(getMinAdvancePeriod);
    var bookingDateSlot = useSelector(getBookingDateSlot);
    var booking = useSelector(getBooking);
    var _a = useState(selectedDateValue
        ? dayjs(selectedDateValue).format('MMYY')
        : dayjs().format('MMYY')), selectedMonth = _a[0], setSelectedMonth = _a[1];
    useEffect(function () {
        // Store is set on step2 or within <AmendCalendar />
        if (selectedStoreServiceId) {
            dispatch(actions.loadDateAvailability({ selectedMonth: selectedMonth, selectedStoreServiceId: selectedStoreServiceId }));
        }
    }, [selectedMonth, selectedStoreServiceId]);
    // `booking` available only on amend journey
    useEffect(function () {
        if (booking) {
            setSelectedMonth(dayjs(booking.datetime).format('MMYY'));
        }
    }, [booking]);
    // `bookingDateSlot` available only on amend journey
    useEffect(function () {
        if (bookingDateSlot) {
            dispatch(actions.selectDateAvailabilityItem(bookingDateSlot));
        }
    }, [bookingDateSlot]);
    var handleSelectDate = function (dateValue) {
        var date = dateAvailability.find(function (item) { return item.date === dateValue; });
        dispatch(actions.selectDateAvailabilityItem(date));
        dispatch(basketActions.resetBasketError());
        dispatch(timeActions.deselectTimeAvailabilityItem());
    };
    return (React.createElement(Calendar, { isLoading: isServiceIdLoading || isDateAvailabilityLoading, minDate: dayjs().add(minAdvancePeriod, 'seconds').format('YYYY-MM-DD'), maxDate: dayjs().add(maxAdvancePeriod, 'seconds').format('YYYY-MM-DD'), preselectedDate: selectedDateValue, unavailableDates: unavailableDates, onSelectDate: handleSelectDate, onSelectMonth: setSelectedMonth }));
};
export { DateSelection };
