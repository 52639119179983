import { createSelector } from 'reselect';
export var getRoot = function (state) {
    return state.analytics;
};
export var getIsTealiumReady = createSelector(getRoot, function (_a) {
    var isTealiumReady = _a.isTealiumReady;
    return isTealiumReady;
});
export var getEventQueue = createSelector(getRoot, function (_a) {
    var eventQueue = _a.eventQueue;
    return eventQueue;
});
