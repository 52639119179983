import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["SET_USER_COORDINATES"] = "@hip/appointment-booking/location/coordinates/SET_USER_COORDINATES";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    setUserCoordinates: function (coordinates) {
        return createAction(ActionTypes.SET_USER_COORDINATES, { coordinates: coordinates });
    },
};
