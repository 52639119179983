var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionTypes } from './actions';
export var initialState = {
    isLoading: false,
    error: null,
    errorType: null,
    cancelIsLoading: false,
    cancelError: null,
    amendIsLoading: false,
    amendError: null,
    data: {
        store: null,
        purchase: null,
    },
};
export var appointment = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.GET_APPOINTMENT_DATA: {
            return __assign(__assign({}, state), { isLoading: true, error: null });
        }
        case ActionTypes.GET_APPOINTMENT_DATA_SUCCESS: {
            return __assign(__assign({}, state), { data: action.payload, isLoading: false });
        }
        case ActionTypes.GET_APPOINTMENT_DATA_FAILURE: {
            return __assign(__assign({}, state), { isLoading: false, error: action.payload.error || null, errorType: action.payload.errorType || null });
        }
        case ActionTypes.CLEAR_ERROR: {
            return __assign(__assign({}, state), { error: null });
        }
        case ActionTypes.DELETE_APPOINTMENT: {
            return __assign(__assign({}, state), { cancelIsLoading: true, cancelError: null });
        }
        case ActionTypes.DELETE_APPOINTMENT_SUCCESS: {
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { store: null, purchase: action.payload.purchase }), errorType: action.payload.errorType, cancelIsLoading: false });
        }
        case ActionTypes.DELETE_APPOINTMENT_FAILURE: {
            return __assign(__assign({}, state), { cancelIsLoading: false, cancelError: action.payload.error || null, errorType: action.payload.errorType || null });
        }
        case ActionTypes.CLEAR_DELETE_ERROR: {
            return __assign(__assign({}, state), { cancelError: null });
        }
        case ActionTypes.AMEND_APPOINTMENT: {
            return __assign(__assign({}, state), { amendIsLoading: true });
        }
        case ActionTypes.AMEND_APPOINTMENT_SUCCESS: {
            return __assign(__assign({}, state), { amendIsLoading: false });
        }
        case ActionTypes.AMEND_APPOINTMENT_FAILURE: {
            return __assign(__assign({}, state), { amendIsLoading: false, amendError: action.payload.error });
        }
        case ActionTypes.CLEAR_AMEND_ERROR: {
            return __assign(__assign({}, state), { amendError: null });
        }
        default:
            return state;
    }
};
