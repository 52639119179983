import { createSelector } from 'reselect';
export var getPersonalDetailsFormState = function (state) { return state.personalDetails; };
export var getIsPersonalDetailsFormValid = createSelector(getPersonalDetailsFormState, function (state) {
    return Object.values(state).every(function (field) { return field.isValid; });
});
export var getPersonalDetailsValues = createSelector(getPersonalDetailsFormState, function (state) {
    return Object.entries(state).reduce(function (acc, _a) {
        var key = _a[0], entry = _a[1];
        acc[key] = entry.value;
        return acc;
    }, {});
});
