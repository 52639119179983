import { combineReducers } from 'redux';
import { coordinates } from './coordinates';
import { stores } from './stores';
import { localities } from './localities';
import { selectedStore } from './selectedStore';
export var location = combineReducers({
    stores: stores,
    coordinates: coordinates,
    localities: localities,
    selectedStore: selectedStore,
});
