import React from 'react';
import { ButtonIcon, Text, IconArrowLeft, IconArrowRight, } from '@realm-kf/halcyon-components-react';
import { dataTestId } from '@hip/test-attributes';
import styles from './header.css';
var Header = React.memo(function (_a) {
    var title = _a.title, isNextMonthAvailable = _a.isNextMonthAvailable, isPrevMonthAvailable = _a.isPrevMonthAvailable, onSelectNextMonth = _a.onSelectNextMonth, onSelectPrevMonth = _a.onSelectPrevMonth;
    return (React.createElement("div", { className: styles.HeaderWrapper },
        React.createElement(ButtonIcon, { "data-testid": dataTestId.ab.calendar.previousMonthArrow, classes: styles.NavButton, icon: React.createElement(IconArrowLeft, { size: 36 }), label: "ArrowLeft", disabled: !isPrevMonthAvailable, onClick: onSelectPrevMonth }),
        React.createElement(Text, { align: "center", size: "largeResponsive", weight: "bold", "data-testid": dataTestId.ab.calendar.monthName }, title),
        React.createElement(ButtonIcon, { "data-testid": dataTestId.ab.calendar.nextMonthArrow, classes: styles.NavButton, icon: React.createElement(IconArrowRight, { size: 36 }), label: "ArrowRight", disabled: !isNextMonthAvailable, onClick: onSelectNextMonth })));
});
export { Header };
