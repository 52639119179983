import React from 'react';
import classnames from 'classnames';
import { Accordion, Text } from '@realm-kf/halcyon-components-react';
import { dataTestId } from '@hip/test-attributes';
import { StoreCard } from '../storeCard/storeCard';
import styles from './storeList.css';
var StoreList = function (_a) {
    var activeStore = _a.activeStore, showFullDetails = _a.showFullDetails, stores = _a.stores, clickStoreWrapper = _a.clickStoreWrapper, _b = _a.showDistanceFromUser, showDistanceFromUser = _b === void 0 ? true : _b, _c = _a.isVirtualHub, isVirtualHub = _c === void 0 ? false : _c;
    var accordionItems = stores.map(function (store) { return ({
        heading: (React.createElement("div", { className: styles.AccordionItemHeader, onClickCapture: function () { return clickStoreWrapper(store); }, "data-testid": dataTestId.ab.storeDetails.storeCard.storeCardHeader },
            React.createElement(Text, { as: "h4", size: "large", "data-testid": dataTestId.ab.storeDetails.storeCard.storeName }, store.name),
            showDistanceFromUser && store.distanceFromUser && (React.createElement(Text, { as: "span", size: "small", classes: styles.DistanceFromUserText, "data-testid": dataTestId.ab.storeDetails.storeCard.distanceFromUser }, store.distanceFromUser.text)))),
        content: (React.createElement("div", { className: styles.StoreCardWrapper },
            React.createElement(StoreCard, { showFullDetails: showFullDetails, store: store, showStoreLink: !isVirtualHub }))),
        active: store === activeStore,
    }); });
    return (React.createElement(Accordion, { items: accordionItems, classes: classnames(styles.Accordion, 'hlcn-u-pr-medium'), isSingleOpen: true }));
};
export { StoreList };
