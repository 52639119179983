import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["INIT_ANALYTICS"] = "@hip/appointment-booking/INIT_ANALYTICS";
    ActionTypes["SEND_ANALYTICS_EVENT"] = "@hip/appointment-booking/SEND_ANALYTICS_EVENT";
    ActionTypes["ADD_TO_QUEUE"] = "@hip/appointment-booking/ADD_TO_QUEUE";
    ActionTypes["CLEAR_QUEUE"] = "@hip/appointment-booking/CLEAR_QUEUE";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    initAnalytics: function () { return createAction(ActionTypes.INIT_ANALYTICS); },
    sendAnalyticsEvent: function (payload) {
        return createAction(ActionTypes.SEND_ANALYTICS_EVENT, payload);
    },
    addToQueue: function (payload) {
        return createAction(ActionTypes.ADD_TO_QUEUE, payload);
    },
    clearQueue: function () { return createAction(ActionTypes.CLEAR_QUEUE); },
};
