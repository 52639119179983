var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionTypes } from './actions';
export var initialState = {
    isLoading: false,
    data: null,
    error: null,
};
export var checkout = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.CHECKOUT_BASKET: {
            return __assign(__assign({}, state), { isLoading: true, error: null });
        }
        case ActionTypes.CHECKOUT_BASKET_SUCCESS:
            return __assign(__assign({}, state), { isLoading: false, data: action.payload.data, error: null });
        case ActionTypes.SET_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload.error });
        case ActionTypes.CLEAR_ERROR:
            return __assign(__assign({}, state), { isLoading: false, error: null });
        default:
            return state;
    }
};
