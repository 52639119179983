import React from 'react';
import { dataTestId as testId } from '@hip/test-attributes';
import { Alert, Button, Text, Grid, GridItem, Loading, } from '@realm-kf/halcyon-components-react';
import { _t } from '@hip/translations';
import styles from './timeSlot.css';
var TimeSlot = function (_a) {
    var _b = _a.timeAvailability, timeAvailability = _b === void 0 ? [] : _b, selectedTimeSlot = _a.selectedTimeSlot, onSelect = _a.onSelect, title = _a.title, _c = _a.isLoading, isLoading = _c === void 0 ? false : _c, _d = _a.isWarningMsgActive, isWarningMsgActive = _d === void 0 ? false : _d, dataTestId = _a.dataTestId;
    return (React.createElement("div", { className: styles.TimeSlotWrapper + " hlcn-u-align-center-column hlcn-u-bgc-light hlcn-u-p-component-large" },
        title && (React.createElement(Text, { as: "h2", size: "medium", align: "center", weight: "bold", space: "large" }, title)),
        React.createElement("div", { className: styles.TimeSlotContent, "data-testid": testId.ab.calendar.timeSlotsBoard },
            isWarningMsgActive && (React.createElement(Alert, { title: _t('appointment_booking.steps.datetime.appointment_unavailable'), content: _t('appointment_booking.steps.datetime.select_diff_time'), variant: "warning" })),
            isLoading ? (React.createElement(Loading, { primary: true, show: true })) : (React.createElement(Grid, { responsive: "full-small", padding: "small", classes: isWarningMsgActive && 'hlcn-u-pt-large' }, timeAvailability &&
                timeAvailability.map(function (timeSlot) {
                    var time = timeSlot.time, datetime = timeSlot.datetime, formatedDatetime = timeSlot.formatedDatetime;
                    return (React.createElement(GridItem, { col: 6, key: time },
                        React.createElement(Button, { "data-testid": dataTestId, variant: (selectedTimeSlot === null || selectedTimeSlot === void 0 ? void 0 : selectedTimeSlot.datetime) === datetime
                                ? 'secondary-dark'
                                : 'tertiary-dark', onClick: function () { return onSelect(timeSlot); }, fullWidth: "full" },
                            React.createElement(Text, null, formatedDatetime))));
                }))))));
};
export { TimeSlot };
