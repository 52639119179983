import { createSelector } from 'reselect';
import { getCategory, getJrniCallCentreID } from '../../utils/selectors';
import { JrniServiceCategory } from '../../utils/interfaces';
var getRoot = function (state) {
    return state.location.stores;
};
export var getStores = createSelector(getRoot, function (stores) { return stores.data; });
export var getStoresIsLoading = createSelector(getRoot, function (stores) { return stores.isLoading; });
export var getActiveStore = createSelector(getRoot, function (stores) { return stores.active; });
export var getActiveStoreJrniId = createSelector(getRoot, getJrniCallCentreID, getCategory, function (stores, callCentreID, appointmentCategory) {
    var _a;
    if (appointmentCategory === JrniServiceCategory.EnergyAssessment) {
        return callCentreID;
    }
    return (_a = stores.active) === null || _a === void 0 ? void 0 : _a.jrniId;
});
export var getStoreError = createSelector(getRoot, function (stores) { return stores.error; });
export var getVirtualHubStore = createSelector(getRoot, function (stores) { return stores.virtualHub; });
