import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["LOAD_TIME_AVAILABILITY"] = "@hip/appointment-booking/timeslot/timeAvailability/LOAD_TIME_AVAILABILITY";
    ActionTypes["LOAD_TIME_AVAILABILITY_SUCCESS"] = "@hip/appointment-booking/timeslot/timeAvailability/LOAD_TIME_AVAILABILITY_SUCCESS";
    ActionTypes["LOAD_TIME_AVAILABILITY_FAILURE"] = "@hip/appointment-booking/timeslot/timeAvailability/LOAD_TIME_AVAILABILITY_FAILURE";
    ActionTypes["SELECT_TIME_AVAILABILITY_ITEM"] = "@hip/appointment-booking/timeslot/timeAvailability/SELECT_TIME_AVAILABILITY_ITEM";
    ActionTypes["DESELECT_TIME_AVAILABILITY_ITEM"] = "@hip/appointment-booking/timeslot/timeAvailability/DESELECT_TIME_AVAILABILITY_ITEM";
    ActionTypes["RESET_TIME_AVAILABILITY"] = "@hip/appointment-booking/timeslot/timeAvailability/RESET_TIME_AVAILABILITY";
    ActionTypes["RESET_TIME_AVAILABILITY_ERROR"] = "@hip/appointment-booking/timeslot/timeAvailability/RESET_TIME_AVAILABILITY_ERROR";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    loadTimeAvailability: function () { return createAction(ActionTypes.LOAD_TIME_AVAILABILITY); },
    loadTimeAvailabilitySuccess: function (payload) {
        return createAction(ActionTypes.LOAD_TIME_AVAILABILITY_SUCCESS, payload);
    },
    loadTimeAvailabilityFailure: function (error) {
        return createAction(ActionTypes.LOAD_TIME_AVAILABILITY_FAILURE, error);
    },
    selectTimeAvailabilityItem: function (time) {
        return createAction(ActionTypes.SELECT_TIME_AVAILABILITY_ITEM, { time: time });
    },
    deselectTimeAvailabilityItem: function () {
        return createAction(ActionTypes.DESELECT_TIME_AVAILABILITY_ITEM);
    },
    resetTimeAvailability: function () {
        return createAction(ActionTypes.RESET_TIME_AVAILABILITY);
    },
    resetTimeAvailabilityError: function () {
        return createAction(ActionTypes.RESET_TIME_AVAILABILITY_ERROR);
    },
};
