export var TealiumEventType;
(function (TealiumEventType) {
    TealiumEventType["link"] = "link";
    TealiumEventType["view"] = "view";
})(TealiumEventType || (TealiumEventType = {}));
export var ANALYTICS_CATEGORY = 'Appointment Booking';
export var AnalyticsEvents;
(function (AnalyticsEvents) {
    AnalyticsEvents["START_BOOKING"] = "START_BOOKING";
    AnalyticsEvents["SELECT_SERVICE"] = "SELECT_SERVICE";
    AnalyticsEvents["SELECT_STORE"] = "SELECT_STORE";
    AnalyticsEvents["DATETIME"] = "DATE/TIME";
    AnalyticsEvents["DETAILS"] = "DETAILS";
    AnalyticsEvents["CONFIRMATION"] = "CONFIRMATION";
    AnalyticsEvents["AMEND"] = "AMEND";
    AnalyticsEvents["AMEND_CONFIRMATION"] = "AMEND_CONFIRMATION";
    AnalyticsEvents["CANCEL_CONFIRMATION"] = "CANCEL_CONFIRMATION";
    AnalyticsEvents["GENERIC_ERROR"] = "GENERIC_ERROR";
    AnalyticsEvents["AMEND_GENERIC_ERROR"] = "AMEND_GENERIC_ERROR";
})(AnalyticsEvents || (AnalyticsEvents = {}));
