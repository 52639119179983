import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, MainContent, Toast, Grid, GridItem, Loading, } from '@realm-kf/halcyon-components-react';
import { _t } from '@hip/translations';
import { Layout } from '../../components/layout';
import { actions, getIsAddingToBasket } from '../../core/basket';
import { getActiveStore } from '../../core/location/stores';
import { getIsSelectedTimeAvailable, getSelectedTimeItem, } from '../../core/timeslot/timeAvailability';
import { actions as analyticsActions, AnalyticsEventMap, AnalyticsEvents, makeAnalyticsPayload, } from '../../core/analytics';
import { useHeaderTranslations } from '../../hooks/useHeaderTranslations';
import { getIsErrorActiveInDateTime } from '../../core/selectors';
import { useBookingSteps } from '../../hooks/useBookingSteps';
import { DateSelection } from './dateSelection';
import { TimeSelection } from './timeSelection';
var TimeslotStep = function () {
    var _a;
    var history = useHistory();
    var dispatch = useDispatch();
    var isAddingToBasket = useSelector(getIsAddingToBasket);
    var selectedStore = useSelector(getActiveStore);
    var selectedTime = useSelector(getSelectedTimeItem);
    var isErrorInDateTime = useSelector(getIsErrorActiveInDateTime);
    var isTimeslotAvailable = useSelector(getIsSelectedTimeAvailable);
    var nextStep = useBookingSteps().nextStep;
    var isPrimaryBtnDisabled = !selectedTime || isAddingToBasket || !isTimeslotAvailable;
    var _b = useHeaderTranslations({
        storeName: selectedStore === null || selectedStore === void 0 ? void 0 : selectedStore.name,
        storePhone: (_a = selectedStore === null || selectedStore === void 0 ? void 0 : selectedStore.address) === null || _a === void 0 ? void 0 : _a.phone,
    }), title = _b.title, subtitle = _b.subtitle;
    var handlePrimaryBtnClick = useCallback(function () {
        dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.DATETIME, selectedTime === null || selectedTime === void 0 ? void 0 : selectedTime.datetime)));
        dispatch(actions.addToBasket({
            onSuccess: function () { return history.push(nextStep); },
        }));
    }, [selectedTime]);
    useEffect(function () {
        if (isErrorInDateTime) {
            dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.GENERIC_ERROR, AnalyticsEventMap[AnalyticsEvents.DATETIME].action)));
        }
    }, [isErrorInDateTime]);
    var handleSecondaryBtnClick = useCallback(history.goBack, []);
    return (React.createElement(Layout, { title: title, subtitle: subtitle, footerProps: {
            primaryLabel: isAddingToBasket ? (React.createElement(Loading, { show: true })) : (_t('appointment_booking.footer.continue')),
            primaryDisabled: isPrimaryBtnDisabled,
            primaryClick: handlePrimaryBtnClick,
            secondaryLabel: _t('appointment_booking.footer.back'),
            secondaryClick: handleSecondaryBtnClick,
        } },
        React.createElement(Toast, { content: _t('appointment_booking.common.sth_went_wrong'), fromTop: "0", isVisible: isErrorInDateTime, variant: "warning" }),
        React.createElement(MainContent, null,
            React.createElement(Container, null,
                React.createElement(Grid, { responsive: "full-large" },
                    React.createElement(GridItem, { col: 8 },
                        React.createElement(DateSelection, null)),
                    React.createElement(GridItem, { col: 4 },
                        React.createElement(TimeSelection, null)))))));
};
export { TimeslotStep };
