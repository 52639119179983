import React from 'react';
import { Text } from '@realm-kf/halcyon-components-react';
import { dataTestId } from '@hip/test-attributes';
import styles from './styles.css';
var Header = function (_a) {
    var title = _a.title, subtitle = _a.subtitle;
    return (React.createElement("header", { className: styles.Header, "data-testid": dataTestId.ab.header.headerArea },
        React.createElement("div", { className: styles.HeaderContent },
            React.createElement(Text, { as: "h1", size: "xxxlargeResponsive", space: "medium", "data-testid": dataTestId.ab.header.headerTitle }, title),
            React.createElement(Text, { as: "p", size: "largeResponsive", "data-testid": dataTestId.ab.header.headerSubtitle }, subtitle))));
};
export { Header };
