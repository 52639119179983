export var keyCodes = {
    TAB: 9,
    ENTER: 13,
    ESCAPE: 27,
    PAGE_UP: 33,
    SPACE: 32,
    PAGE_DOWN: 34,
    END: 35,
    HOME: 36,
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40,
};
