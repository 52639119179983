import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from '@realm-kf/halcyon-components-react';
import { _t } from '@hip/translations';
import { TypeAheadInput } from '../../../components/typeAheadInput/typeAheadInput';
import { debounce } from '../../../core/location/localities/utils';
import { actions, getLocalities, getLocalitiesError, actions as localitiesActions, } from '../../../core/location/localities';
import { actions as analyticsActions, AnalyticsEvents, makeAnalyticsPayload, } from '../../../core/analytics';
import styles from './locationSearch.css';
var LocationSearch = function () {
    var dispatch = useDispatch();
    var localities = useSelector(getLocalities);
    var localitiesError = useSelector(getLocalitiesError);
    var options = useMemo(function () {
        return localities.map(function (_a) {
            var description = _a.description, public_id = _a.public_id;
            return ({
                label: description,
                id: public_id,
            });
        });
    }, [localities]);
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var handleChange = function (value) { return setValue(value); };
    var handleSelect = function (option) {
        var locality = localities.find(function (item) { return item.public_id === option.id; });
        dispatch(localitiesActions.loadLocalityDetails(locality.public_id));
        setValue(option.label);
    };
    var loadOptions = debounce(function (search) { return dispatch(actions.loadLocalities(search)); }, 500);
    useEffect(function () {
        var trimmed = value.trim();
        if (trimmed) {
            loadOptions(trimmed);
        }
    }, [value]);
    useEffect(function () {
        if (localitiesError) {
            dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.GENERIC_ERROR, 'Step 2')));
        }
    }, [localitiesError]);
    return (React.createElement("div", { className: styles.LocationSearchWrapper },
        React.createElement("div", { className: styles.LocationSearch },
            React.createElement(TypeAheadInput, { options: options, onChange: handleChange, onSelect: handleSelect, placeholder: _t('appointment_booking.steps.store.search_placeholder'), value: value })),
        React.createElement(Toast, { content: _t('appointment_booking.common.sth_went_wrong'), fromTop: "0", isVisible: Boolean(localitiesError), variant: "warning" })));
};
export { LocationSearch };
