import React from 'react';
import classnames from 'classnames';
import { Hr } from '@realm-kf/halcyon-components-react';
import { IntlPhoneFlagIcon } from '../intlPhoneFlagIcon/intlPhoneFlagIcon';
import styles from './intlPhoneOption.css';
var IntlPhoneOption = function (_a) {
    var country = _a.country, onClick = _a.onClick, onKeyDown = _a.onKeyDown, refCallback = _a.refCallback, showSeparator = _a.showSeparator;
    return (React.createElement("li", { className: classnames(styles.IntlPhoneNumberInput__Option), onClick: function () { return onClick(country); }, onKeyDown: onKeyDown(country), tabIndex: -1, ref: refCallback(country.iso2) },
        React.createElement("span", { className: "hlcn-u-p-component-small hlcn-u-align-center" },
            React.createElement(IntlPhoneFlagIcon, { iso2: country.iso2, classes: "hlcn-u-mr-small" }), country.name + " (+" + country.dialCode + ")"),
        showSeparator && React.createElement(Hr, null)));
};
export { IntlPhoneOption };
