import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CategoryStep, StoreStep, TimeslotStep, PersonalDetailsStep, ConfirmationStep, AmendBooking, AmendCalendar, AmendSuccess, } from './containers';
export var RouteList;
(function (RouteList) {
    RouteList["ROOT"] = "/";
    RouteList["CATEGORY"] = "/category";
    RouteList["STORE"] = "/store";
    RouteList["DATETIME"] = "/datetime";
    RouteList["CONTACTS"] = "/contacts";
    RouteList["CONFIRMATION"] = "/confirmation";
    RouteList["NOT_FOUND"] = "/not-found";
    RouteList["AMEND"] = "/amend";
    RouteList["AMEND_CALENDAR"] = "/amend/calendar";
    RouteList["SUCCESS"] = "/success";
})(RouteList || (RouteList = {}));
export var Routes = function () { return (React.createElement(Switch, null,
    React.createElement(Route, { exact: true, path: RouteList.CATEGORY, component: CategoryStep }),
    React.createElement(Route, { exact: true, path: RouteList.STORE, component: StoreStep }),
    React.createElement(Route, { exact: true, path: RouteList.DATETIME, component: TimeslotStep }),
    React.createElement(Route, { exact: true, path: RouteList.CONTACTS, component: PersonalDetailsStep }),
    React.createElement(Route, { exact: true, path: RouteList.CONFIRMATION + "/:appointmentId", component: ConfirmationStep }),
    React.createElement(Route, { exact: true, path: RouteList.AMEND + "/:appointmentId", component: AmendBooking }),
    React.createElement(Route, { exact: true, path: RouteList.AMEND_CALENDAR + "/:appointmentId", component: AmendCalendar }),
    React.createElement(Route, { exact: true, path: RouteList.AMEND + "/:appointmentId" + RouteList.SUCCESS, component: AmendSuccess }),
    React.createElement(Route, { exact: true, path: RouteList.NOT_FOUND },
        React.createElement("div", null, "404 - NOT FOUND")),
    React.createElement(Redirect, { exact: true, from: RouteList.ROOT, to: RouteList.CATEGORY }),
    React.createElement(Redirect, { from: "*", to: RouteList.NOT_FOUND }))); };
