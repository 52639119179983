import React from 'react';
import classnames from 'classnames';
import { Text } from '@realm-kf/halcyon-components-react';
import styles from './cell.css';
var Cell = React.memo(function (_a) {
    var _b;
    var date = _a.date, disabled = _a.disabled, isSelected = _a.isSelected, isToday = _a.isToday, startColumn = _a.startColumn, onSelect = _a.onSelect, dataTestId = _a.dataTestId;
    var cellClassNames = classnames((_b = {},
        _b[styles.Cell] = true,
        _b[styles.Cell_selected] = isSelected,
        _b[styles.Cell_disabled] = disabled,
        _b));
    var dateClassNames = classnames(isToday && styles.Today);
    return (React.createElement("li", { "data-testid": dataTestId, className: cellClassNames, style: startColumn && { gridColumnStart: startColumn }, onClick: function () { return !disabled && onSelect(date); } },
        React.createElement(Text, { classes: dateClassNames, size: "largeResponsive", weight: "bold" }, date.format('D'))));
});
export { Cell };
