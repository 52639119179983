var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { ActionTypes } from './actions';
export var initialState = {
    data: null,
    isLoading: false,
    error: null,
    selected: null,
    unavailable: [],
};
export var dateAvailability = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.LOAD_DATE_AVAILABILITY:
            return __assign(__assign({}, state), { data: null, isLoading: true, error: null });
        case ActionTypes.LOAD_DATE_AVAILABILITY_SUCCESS:
            return __assign(__assign({}, state), { data: action.payload, isLoading: false });
        case ActionTypes.LOAD_DATE_AVAILABILITY_FAILURE:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload });
        case ActionTypes.SELECT_DATE_AVAILABILITY_ITEM:
            return __assign(__assign({}, state), { selected: action.payload.date });
        case ActionTypes.DESELECT_DATE_AVAILABILITY_ITEM:
            return __assign(__assign({}, state), { selected: null });
        case ActionTypes.RESET_DATE_AVAILABILITY:
            return __assign({}, initialState);
        case ActionTypes.RESET_DATE_AVAILABILITY_ERROR:
            return __assign(__assign({}, state), { error: null });
        case ActionTypes.MARK_DATES_UNAVAILABLE:
            return __assign(__assign({}, state), { unavailable: __spreadArrays(state.unavailable, action.payload.dates) });
        default:
            return state;
    }
};
