import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["LOAD_LOCALITIES"] = "@hip/appointment-booking/location/localities/LOAD_LOCALITIES";
    ActionTypes["LOAD_LOCALITIES_SUCCESS"] = "@hip/appointment-booking/location/localities/LOAD_LOCALITIES_SUCCESS";
    ActionTypes["LOAD_LOCALITIES_FAILURE"] = "@hip/appointment-booking/location/localities/LOAD_LOCALITIES_FAILURE";
    ActionTypes["LOAD_LOCALITY_DETAILS"] = "@hip/appointment-booking/location/localities/LOAD_LOCALITY_DETAILS";
    ActionTypes["REMOVE_LOCALITIES_ERROR"] = "@hip/appointment-booking/location/localities/REMOVE_LOCALITIES_ERROR";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    loadLocalities: function (search) {
        return createAction(ActionTypes.LOAD_LOCALITIES, { search: search });
    },
    loadLocalitiesSuccess: function (localities) {
        return createAction(ActionTypes.LOAD_LOCALITIES_SUCCESS, { localities: localities });
    },
    loadLocalitiesFailure: function (error) {
        return createAction(ActionTypes.LOAD_LOCALITIES_FAILURE, { error: error });
    },
    loadLocalityDetails: function (publicId) {
        return createAction(ActionTypes.LOAD_LOCALITY_DETAILS, { publicId: publicId });
    },
    removeLocalitiesError: function () {
        return createAction(ActionTypes.REMOVE_LOCALITIES_ERROR);
    },
};
