import React from 'react';
import { Text } from '@realm-kf/halcyon-components-react';
import { dataTestId } from '@hip/test-attributes';
import { Progress } from '../progress/progress';
import { useBookingSteps } from '../../hooks/useBookingSteps';
import styles from './styles.css';
var Steps = function () {
    var _a = useBookingSteps(), currentStepNumber = _a.currentStepNumber, totalSteps = _a.totalSteps;
    return (Boolean(currentStepNumber) && (React.createElement("div", { className: styles.StepsWrapper, "data-testid": dataTestId.ab.header.stepProgressBar },
        React.createElement(Text, { as: "p", weight: "bold", classes: styles.StepsText, size: "medium" },
            "Step ",
            currentStepNumber,
            " of ",
            totalSteps),
        React.createElement(Progress, { percentage: (currentStepNumber / totalSteps) * 100 }))));
};
export { Steps };
