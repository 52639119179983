import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { MainContent, Text, Link, Toast, Grid, GridItem, Loading, } from '@realm-kf/halcyon-components-react';
import { _t } from '@hip/translations';
import { dataTestId } from '@hip/test-attributes';
import { actions as basketActions } from '../../core/basket/actions';
import { actions } from '../../core/checkout/actions';
import { getCheckoutError, getIsCheckoutBasketLoading, } from '../../core/checkout/selectors';
import { BookingInfo } from '../../components/bookingInfo/bookingInfo';
import { PersonalDetailsForm } from '../../modules/personalDetailsForm/personalDetailsForm';
import { getIsPersonalDetailsFormValid } from '../../core/personalDetails/selectors';
import { getActiveStore } from '../../core/location/stores';
import { Layout } from '../../components/layout';
import { actions as analyticsActions, AnalyticsEventMap, AnalyticsEvents, makeAnalyticsPayload, } from '../../core/analytics';
import { useHeaderTranslations } from '../../hooks/useHeaderTranslations';
import { useBookingSteps } from '../../hooks/useBookingSteps';
import styles from './personalDetailsStep.css';
var PersonalDetailsStep = function () {
    var _a;
    var isCheckoutBasketLoading = useSelector(getIsCheckoutBasketLoading);
    var selectedStore = useSelector(getActiveStore);
    var checkoutError = useSelector(getCheckoutError);
    var _b = useHeaderTranslations({
        storeName: selectedStore === null || selectedStore === void 0 ? void 0 : selectedStore.name,
        storePhone: (_a = selectedStore === null || selectedStore === void 0 ? void 0 : selectedStore.address) === null || _a === void 0 ? void 0 : _a.phone,
    }), title = _b.title, subtitle = _b.subtitle;
    var nextStep = useBookingSteps().nextStep;
    var history = useHistory();
    var dispatch = useDispatch();
    useEffect(function () {
        if (checkoutError) {
            dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.GENERIC_ERROR, AnalyticsEventMap[AnalyticsEvents.DETAILS].action)));
        }
    }, [checkoutError]);
    var handleSubmit = function () {
        dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.DETAILS, 'Booking completed')));
        dispatch(actions.checkoutBasket({
            onSuccess: function (purchaseId) {
                return history.push(nextStep + "/" + purchaseId);
            },
        }));
    };
    var handleGoBack = function () {
        dispatch(basketActions.removeFromBasket({
            onRedirect: function () { return history.goBack(); },
        }));
    };
    var isFormValid = useSelector(getIsPersonalDetailsFormValid);
    return (React.createElement(Layout, { title: title, subtitle: subtitle, footerProps: {
            primaryLabel: isCheckoutBasketLoading ? (React.createElement(Loading, { show: true })) : (_t('appointment_booking.footer.complete_booking')),
            primaryClick: handleSubmit,
            secondaryLabel: _t('appointment_booking.footer.back'),
            secondaryClick: handleGoBack,
            primaryDisabled: !isFormValid || isCheckoutBasketLoading,
        } },
        React.createElement(MainContent, null,
            React.createElement(Grid, { responsive: "full-large" },
                React.createElement(GridItem, { col: 8 },
                    React.createElement("div", { className: classnames('hlcn-u-bgc-light', 'hlcn-u-p-component-large') },
                        React.createElement(Toast, { content: _t('appointment_booking.common.sth_went_wrong'), fromTop: "0", isVisible: !!checkoutError, variant: "warning" }),
                        React.createElement("div", { className: styles.PersonalDetailsFormWrapper },
                            React.createElement(PersonalDetailsForm, { onSubmit: handleSubmit })),
                        React.createElement("div", { "data-testid": dataTestId.ab.personalDetails.policyBlock, className: classnames('hlcn-u-p-component', 'hlcn-u-mt-large', styles.PrivacyPolicy) },
                            React.createElement(Text, null,
                                _t('appointment_booking.steps.contacts.privacy_policy.text1'),
                                React.createElement(Link, { inNewTab: true, linkUnderline: true, href: _t('appointment_booking.steps.contacts.privacy_policy.href') }, _t('appointment_booking.steps.contacts.privacy_policy.label')),
                                _t('appointment_booking.steps.contacts.privacy_policy.text2'))))),
                React.createElement(GridItem, { col: 4, classes: styles.GridItemWithBookingInfo },
                    React.createElement(BookingInfo, null))))));
};
export { PersonalDetailsStep };
