import React from 'react';
import { _t } from '@hip/translations';
import { dataTestId } from '@hip/test-attributes';
import { Alert, Link, Button } from '@realm-kf/halcyon-components-react';
import classnames from 'classnames';
import styles from './cancelBooking.css';
var CancelBooking = function (_a) {
    var onAbort = _a.onAbort, onCancel = _a.onCancel;
    return (React.createElement("div", { "data-testid": dataTestId.ab.appointmentAmendments.cancellationPopUp, className: classnames(styles.CancelBooking, 'hlcn-alert--warning', 'hlcn-u-font-medium', 'hlcn-u-align-space-between', 'hlcn-u-mt-xsmall', 'hlcn-u-mr-small', 'hlcn-u-ml-small', 'hlcn-u-mb-medium') },
        React.createElement(Alert, { title: _t('appointment_booking.steps.amend.cancel.title'), content: _t('appointment_booking.steps.amend.cancel.subtitle'), variant: "warning" }),
        React.createElement("div", { className: classnames(styles.CancelBookingCTA, 'hlcn-alert', 'hlcn-u-align-wrap', 'hlcn-u-ml-large') },
            React.createElement(Button, { "data-testid": dataTestId.ab.appointmentAmendments.confirmCancelButton, fullWidth: "maxMedium", onClick: onCancel, classes: styles.CancelBookingCTABtnConfirm }, _t('appointment_booking.steps.amend.cancel.cta_cancel')),
            React.createElement(Link, { "data-testid": dataTestId.ab.appointmentAmendments.abortCancelLink, onClick: onAbort, classes: classnames('hlcn-u-pr-large', 'hlcn-u-pt-medium', styles.CancelBookingCTABtnCancel), linkUnderline: true, inNewTab: true }, _t('appointment_booking.steps.amend.cancel.cta_abort')))));
};
export { CancelBooking };
