import React, { useEffect, useState } from 'react';
import { _t } from '@hip/translations';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Toast } from '@realm-kf/halcyon-components-react';
import { AppointmentSummary } from '../../components/appointmentSummary/appointmentSummary';
import { RouteList } from '../../routes';
import { Layout } from '../../components/layout';
import { actions, AppointmentErrorType, getGenericError, getErrorType, getBooking, } from '../../core/appointment';
import { actions as analyticsActions, AnalyticsEventMap, AnalyticsEvents, makeAnalyticsPayload, } from '../../core/analytics';
import { useHeaderTranslations } from '../../hooks/useHeaderTranslations';
import { CancelBooking } from './cancelBooking/cancelBooking';
var AmendBooking = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var appointmentId = useParams().appointmentId;
    var appointmentGenericError = useSelector(getGenericError);
    var appointmentErrorType = useSelector(getErrorType);
    var booking = useSelector(getBooking);
    var _a = useState(false), showCancelPopup = _a[0], setShowCancelPopup = _a[1];
    // Keeping track of whether cancel button was clicked, since
    // specific analytics event should be dispatched in case
    // the appointment was already cancelled on page visit.
    var _b = useState(false), wasCancelled = _b[0], setWasCancelled = _b[1];
    var handleAmend = function () {
        history.push(RouteList.AMEND_CALENDAR + "/" + appointmentId);
    };
    var handleCancelConfirm = function () {
        setWasCancelled(true);
        dispatch(actions.deleteAppointment(appointmentId));
    };
    var _c = useHeaderTranslations({
        appointmentErrorType: appointmentErrorType,
    }), title = _c.title, subtitle = _c.subtitle;
    var showFooter = !appointmentErrorType && !showCancelPopup;
    var primaryLabel = _t('appointment_booking.steps.amend.footer_buttons.amend');
    var secondaryLabel = _t('appointment_booking.steps.amend.footer_buttons.cancel');
    useEffect(function () {
        if (booking) {
            dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.AMEND, 'Appointment found')));
        }
    }, [booking]);
    useEffect(function () {
        if (appointmentErrorType === AppointmentErrorType.appointmentWasCanceled &&
            !wasCancelled) {
            dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.AMEND, 'Already cancelled')));
        }
    }, [appointmentErrorType, wasCancelled]);
    useEffect(function () {
        if (appointmentGenericError) {
            dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.AMEND_GENERIC_ERROR, AnalyticsEventMap[AnalyticsEvents.AMEND].action)));
        }
    }, [appointmentGenericError]);
    return (React.createElement(Layout, { title: title, subtitle: subtitle, footerProps: {
            primaryLabel: primaryLabel,
            primaryClick: showFooter && handleAmend,
            secondaryLabel: secondaryLabel,
            secondaryClick: showFooter && (function () { return setShowCancelPopup(true); }),
            secondaryVariant: 'tertiary-dark',
        } },
        React.createElement(AppointmentSummary, { appointmentId: appointmentId }),
        showCancelPopup && !appointmentErrorType && (React.createElement(CancelBooking, { onAbort: function () { return setShowCancelPopup(false); }, onCancel: handleCancelConfirm })),
        React.createElement(Toast, { content: _t('appointment_booking.common.sth_went_wrong'), fromTop: "0", isVisible: !!appointmentGenericError, variant: "warning" })));
};
export { AmendBooking };
