import { OPCOS } from '@hip/interfaces';
export var makeJrniClientPayload = function (_a) {
    var firstName = _a.firstName, lastName = _a.lastName, emailAddress = _a.emailAddress, dialCode = _a.dialCode, countryCode = _a.countryCode, phoneNumber = _a.phoneNumber, country = _a.country, storeId = _a.storeId;
    var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var locale = Intl.DateTimeFormat().resolvedOptions().locale;
    return {
        consent: false,
        first_name: firstName,
        last_name: lastName,
        country: country,
        email: emailAddress,
        default_company_id: storeId,
        time_zone: tz,
        mobile: phoneNumber,
        mobile_prefix: "+" + dialCode,
        mobile_prefix_country_code: countryCode.toUpperCase(),
        extra_info: {
            locale: locale,
        },
    };
};
export var getCountryFromTenant = function (tenant) {
    switch (tenant) {
        case OPCOS.BQUK:
        case OPCOS.TPUK:
            return 'United Kingdom';
        case OPCOS.BDFR:
        case OPCOS.CAFR:
            return 'France';
        case OPCOS.CAPL:
            return 'Poland';
        case OPCOS.BQIE:
            return 'ROI';
        case OPCOS.BDRO:
            return 'Romania';
        default:
            return null;
    }
};
