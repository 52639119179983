import { useEffect, useState } from 'react';
/**
 * @param mediaQueryString in format '(min-width: 64em)'
 */
export var useMediaQuery = function (mediaQueryString) {
    var _a = useState(window.matchMedia(mediaQueryString).matches), isMediaQueryMatches = _a[0], setIsMediaQueryMatches = _a[1];
    useEffect(function () {
        window.onresize = function () {
            return setIsMediaQueryMatches(window.matchMedia(mediaQueryString).matches);
        };
        return function () { return (window.onresize = null); };
    }, []);
    return isMediaQueryMatches;
};
