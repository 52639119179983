import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["LOAD_STORES"] = "@hip/appointment-booking/location/stores/LOAD_STORES";
    ActionTypes["LOAD_STORES_SUCCESS"] = "@hip/appointment-booking/location/stores/LOAD_STORES_SUCCESS";
    ActionTypes["LOAD_STORES_FAILURE"] = "@hip/appointment-booking/location/stores/LOAD_STORES_FAILURE";
    ActionTypes["SET_ACTIVE_STORE"] = "@hip/appointment-booking/location/stores/SET_ACTIVE_STORE";
    ActionTypes["REMOVE_STORE_ERROR"] = "@hip/appointment-booking/location/stores/REMOVE_STORE_ERROR";
    ActionTypes["LOAD_VIRTUAL_HUB_STORE"] = "@hip/appointment-booking/location/stores/LOAD_VIRTUAL_HUB_STORE";
    ActionTypes["LOAD_VIRTUAL_HUB_STORE_SUCCESS"] = "@hip/appointment-booking/location/stores/LOAD_VIRTUAL_HUB_STORE_SUCCESS";
    ActionTypes["LOAD_VIRTUAL_HUB_STORE_FAILURE"] = "@hip/appointment-booking/location/stores/LOAD_VIRTUAL_HUB_STORE_FAILURE";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    loadStores: function () { return createAction(ActionTypes.LOAD_STORES); },
    loadStoresSuccess: function (data) {
        return createAction(ActionTypes.LOAD_STORES_SUCCESS, { data: data });
    },
    loadStoresFailure: function (error) {
        return createAction(ActionTypes.LOAD_STORES_FAILURE, { error: error });
    },
    setActiveStore: function (store) {
        return createAction(ActionTypes.SET_ACTIVE_STORE, { store: store });
    },
    removeStoresError: function () { return createAction(ActionTypes.REMOVE_STORE_ERROR); },
    loadVirtualHubStore: function () { return createAction(ActionTypes.LOAD_VIRTUAL_HUB_STORE); },
    loadVirtualHubStoreSuccess: function (store) {
        return createAction(ActionTypes.LOAD_VIRTUAL_HUB_STORE_SUCCESS, { store: store });
    },
    loadVirtualHubStoreFailure: function (error) {
        return createAction(ActionTypes.LOAD_VIRTUAL_HUB_STORE_FAILURE, { error: error });
    },
};
