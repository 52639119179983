import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { dataTestId } from '@hip/test-attributes';
import { keyCodes } from './keyCodes';
import { Menu } from './menu/menu';
import styles from './typeAheadInput.css';
var TypeAheadInput = function (_a) {
    var classes = _a.classes, options = _a.options, onSelect = _a.onSelect, onChange = _a.onChange, onFocus = _a.onFocus, onBlur = _a.onBlur, onKeyDown = _a.onKeyDown, placeholder = _a.placeholder, value = _a.value, _b = _a.limitItems, limitItems = _b === void 0 ? 5 : _b;
    var inputRef = useRef(null);
    var _c = useState(true), isMenuOpen = _c[0], setIsMenuOpen = _c[1];
    var _d = useState(-1), activeIndex = _d[0], setActiveIndex = _d[1];
    var autofillRef = useRef(false);
    var closeMenu = function () {
        setIsMenuOpen(false);
        setActiveIndex(-1);
    };
    useEffect(function () {
        var timer = setInterval(function () {
            if (!autofillRef.current &&
                inputRef.current &&
                (inputRef.current.matches(':autofill') ||
                    inputRef.current.matches('*:-webkit-autofill'))) {
                var value_1 = inputRef.current.value;
                onChange && onChange(value_1);
                autofillRef.current = true;
                if (value_1.trim()) {
                    setIsMenuOpen(true);
                }
            }
        }, 500);
        return function () {
            clearInterval(timer);
        };
    }, []);
    var handleChange = useCallback(function (e) {
        var value = e.target.value;
        onChange && onChange(value);
        if (value.trim()) {
            setIsMenuOpen(true);
        }
    }, [onChange]);
    var filteredOptions = useMemo(function () { return options.slice(0, limitItems); }, [
        options,
        limitItems,
    ]);
    var handleFocus = useCallback(function (e) {
        var value = e.target.value.trim();
        value && setIsMenuOpen(true);
        onFocus && onFocus(e);
    }, [onFocus]);
    var handleMenuMouseDown = useCallback(function (_, option) {
        closeMenu();
        onSelect && onSelect(option);
    }, [onSelect]);
    var handleKeyDown = useCallback(function (e) {
        if (e.keyCode === keyCodes.ESCAPE) {
            closeMenu();
        }
        if (isMenuOpen) {
            if (e.keyCode === keyCodes.ARROW_DOWN) {
                setActiveIndex(Math.min(activeIndex + 1, filteredOptions.length - 1));
            }
            if (e.keyCode === keyCodes.ARROW_UP) {
                setActiveIndex(Math.max(activeIndex - 1, 0));
            }
            if (e.keyCode === keyCodes.ENTER &&
                activeIndex >= 0 &&
                activeIndex < filteredOptions.length) {
                var option = filteredOptions[activeIndex];
                onSelect(option);
                closeMenu();
            }
        }
        onKeyDown && onKeyDown(e);
    }, [onKeyDown, filteredOptions, activeIndex]);
    var handleBlur = useCallback(function (e) {
        setIsMenuOpen(false);
        onBlur && onBlur(e);
    }, [onBlur, setIsMenuOpen]);
    return (React.createElement("div", { className: styles.TypeAheadInputWrapper },
        React.createElement("input", { ref: inputRef, "data-testid": dataTestId.ab.storeDetails.typeAheadInput, className: classnames(styles.TypeAheadInput, classes), onChange: handleChange, placeholder: placeholder, onFocus: handleFocus, onBlur: handleBlur, onKeyDown: handleKeyDown, value: value }),
        isMenuOpen && filteredOptions.length > 0 && (React.createElement(Menu, { activeIndex: activeIndex, options: filteredOptions, onMouseDown: handleMenuMouseDown }))));
};
export { TypeAheadInput };
