var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionTypes } from './actions';
export var initialState = {
    data: null,
    isLoading: false,
    error: null,
    selected: null,
};
export var timeAvailability = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.LOAD_TIME_AVAILABILITY:
            return __assign(__assign({}, state), { isLoading: true, error: null });
        case ActionTypes.LOAD_TIME_AVAILABILITY_SUCCESS:
            return __assign(__assign({}, state), { data: action.payload, isLoading: false, error: null });
        case ActionTypes.LOAD_TIME_AVAILABILITY_FAILURE:
            return __assign(__assign({}, state), { isLoading: false, error: action.payload });
        case ActionTypes.SELECT_TIME_AVAILABILITY_ITEM:
            return __assign(__assign({}, state), { selected: action.payload.time });
        case ActionTypes.DESELECT_TIME_AVAILABILITY_ITEM:
            return __assign(__assign({}, state), { selected: null });
        case ActionTypes.RESET_TIME_AVAILABILITY:
            return __assign(__assign({}, initialState), { selected: state.selected });
        case ActionTypes.RESET_TIME_AVAILABILITY_ERROR:
            return __assign(__assign({}, state), { error: null });
        default:
            return state;
    }
};
