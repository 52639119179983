import { jrniAPI } from '../../api/jrniAPI';
export var createBasket = function (companyId) {
    return jrniAPI.post("/baskets", {
        company_id: companyId,
    });
};
export var addTimeslotToBasket = function (basketId, serviceId, start, authToken, companyId) {
    return jrniAPI.post("/baskets/" + basketId + "/service_items", {
        service_id: serviceId,
        start: start,
        company_id: companyId,
    }, {
        headers: {
            'Auth-Token': authToken,
        },
    });
};
export var deleteBasket = function (basketId, authToken) {
    return jrniAPI.delete("/baskets/" + basketId, {
        headers: { 'auth-token': authToken },
    });
};
