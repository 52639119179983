var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeLeading, takeLatest, select, } from 'redux-saga/effects';
import { fetchStore } from '../location/stores/requests';
import { runActionAfterDelay } from '../utils/sagas';
import { actions as analyticsActions, AnalyticsEvents, makeAnalyticsPayload, } from '../../core/analytics';
import { deletePurchase, fetchPurchase, amendPurchase } from './requests';
import { processPurchaseResponse } from './utils';
import { ActionTypes, actions } from './actions';
import { AppointmentErrorType, } from './interfaces';
import { getServiceName } from './selectors';
export function getAppointmentSaga(_a) {
    var purchaseResponse, purchaseData, storeData, error_1, errorType;
    var _b, _c;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 7, , 9]);
                return [4 /*yield*/, call(fetchPurchase, payload.purchaseId)];
            case 1:
                purchaseResponse = _d.sent();
                purchaseData = processPurchaseResponse(purchaseResponse);
                if (!purchaseData.booking) return [3 /*break*/, 4];
                return [4 /*yield*/, call(fetchStore, purchaseData.booking.company_id)];
            case 2:
                storeData = _d.sent();
                return [4 /*yield*/, put(actions.getAppointmentSuccess({
                        store: storeData,
                        purchase: purchaseData,
                    }))];
            case 3:
                _d.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, put(actions.getAppointmentFailure(null, AppointmentErrorType.appointmentWasCanceled))];
            case 5:
                _d.sent();
                _d.label = 6;
            case 6: return [3 /*break*/, 9];
            case 7:
                error_1 = _d.sent();
                errorType = ((_c = (_b = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error) === 'Not Found'
                    ? AppointmentErrorType.appointmentDoNotExist
                    : null;
                return [4 /*yield*/, put(actions.getAppointmentFailure(error_1, errorType))];
            case 8:
                _d.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function deleteAppointmentSaga(_a) {
    var serviceName, purchaseResponse, purchaseData, error_2, errorType;
    var _b, _c;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 5, , 7]);
                return [4 /*yield*/, select(getServiceName)];
            case 1:
                serviceName = _d.sent();
                return [4 /*yield*/, call(deletePurchase, payload.purchaseId)];
            case 2:
                purchaseResponse = _d.sent();
                purchaseData = processPurchaseResponse(purchaseResponse);
                return [4 /*yield*/, put(actions.deleteAppointmentSuccess({
                        purchase: purchaseData,
                        errorType: AppointmentErrorType.appointmentWasCanceled,
                    }))];
            case 3:
                _d.sent();
                return [4 /*yield*/, put(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.CANCEL_CONFIRMATION, serviceName)))];
            case 4:
                _d.sent();
                return [3 /*break*/, 7];
            case 5:
                error_2 = _d.sent();
                errorType = ((_c = (_b = error_2 === null || error_2 === void 0 ? void 0 : error_2.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error) === 'Not Found'
                    ? AppointmentErrorType.appointmentDoNotExist
                    : null;
                return [4 /*yield*/, put(actions.deleteAppointmentFailure(error_2, errorType))];
            case 6:
                _d.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function amendAppointmentSaga(_a) {
    var error_3;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call(amendPurchase, payload.purchaseId, payload.bookingId, payload.datetime)];
            case 1:
                _b.sent();
                return [4 /*yield*/, put(actions.amendAppointmentSuccess())];
            case 2:
                _b.sent();
                if (!payload.callback) return [3 /*break*/, 4];
                return [4 /*yield*/, call(payload.callback)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5:
                error_3 = _b.sent();
                return [4 /*yield*/, put(actions.amendAppointmentFailure(error_3))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function sagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLeading(ActionTypes.GET_APPOINTMENT_DATA, getAppointmentSaga)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLeading(ActionTypes.GET_APPOINTMENT_DATA_FAILURE, function () {
                        return runActionAfterDelay(actions.clearError());
                    })];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLeading(ActionTypes.DELETE_APPOINTMENT, deleteAppointmentSaga)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLeading(ActionTypes.DELETE_APPOINTMENT_FAILURE, function () {
                        return runActionAfterDelay(actions.clearDeleteError());
                    })];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(ActionTypes.AMEND_APPOINTMENT, amendAppointmentSaga)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(ActionTypes.AMEND_APPOINTMENT_FAILURE, function () {
                        return runActionAfterDelay(actions.clearAmendError());
                    })];
            case 6:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
