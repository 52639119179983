var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionTypes } from './actions';
export var initialState = {
    active: null,
    data: null,
    virtualHub: null,
    error: null,
    isLoading: false,
};
export var stores = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.LOAD_STORES:
            return __assign(__assign({}, state), { active: null, error: null, data: null, virtualHub: null, isLoading: true });
        case ActionTypes.LOAD_STORES_SUCCESS:
            return __assign(__assign({}, state), { data: action.payload.data, isLoading: false });
        case ActionTypes.LOAD_STORES_FAILURE:
            return __assign(__assign({}, state), { error: action.payload.error, isLoading: false });
        case ActionTypes.LOAD_VIRTUAL_HUB_STORE:
            return __assign(__assign({}, state), { error: null, virtualHub: null, isLoading: true });
        case ActionTypes.LOAD_VIRTUAL_HUB_STORE_SUCCESS:
            return __assign(__assign({}, state), { virtualHub: action.payload.store, isLoading: false });
        case ActionTypes.LOAD_VIRTUAL_HUB_STORE_FAILURE:
            return __assign(__assign({}, state), { error: action.payload.error, isLoading: false });
        case ActionTypes.SET_ACTIVE_STORE:
            return __assign(__assign({}, state), { active: action.payload.store });
        case ActionTypes.REMOVE_STORE_ERROR:
            return __assign(__assign({}, state), { error: null });
        default:
            return state;
    }
};
