import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["HANDLE_INPUT"] = "@hip/appointment-booking/personalDetails/HANDLE_INPUT";
    ActionTypes["SET_FIELD_DIRTY"] = "@hip/appointment-booking/personalDetails/SET_FIELD_DIRTY";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    handleInput: function (payload) { return createAction(ActionTypes.HANDLE_INPUT, payload); },
    setFieldDirty: function (payload) {
        return createAction(ActionTypes.SET_FIELD_DIRTY, payload);
    },
};
