import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { convertTimeToAmPm, getShortDayOfWeekName, getDayOfTheWeekName, } from '@hip/helpers';
import { _t } from '@hip/translations';
import { Text, Card, List, Button } from '@realm-kf/halcyon-components-react';
import { dataTestId } from '@hip/test-attributes';
import { actions as selectedStoreActions } from '../../core/location/selectedStore';
import { createStoreHref } from '../../helpers/url';
import { getHostUrl } from '../../core/utils/selectors';
import { actions as analyticsActions, AnalyticsEvents, makeAnalyticsPayload, } from '../../core/analytics';
import { useBookingSteps } from '../../hooks/useBookingSteps';
var StoreCard = function (_a) {
    var store = _a.store, _b = _a.showStoreLink, showStoreLink = _b === void 0 ? true : _b, _c = _a.showFullDetails, showFullDetails = _c === void 0 ? true : _c;
    var dispatch = useDispatch();
    var history = useHistory();
    var hostUrl = useSelector(getHostUrl);
    var nextStep = useBookingSteps().nextStep;
    var onStoreSelect = function (selectedStoreJrniId) {
        dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.SELECT_STORE, store.name)));
        dispatch(selectedStoreActions.loadServiceId({
            selectedStoreJrniId: selectedStoreJrniId,
            callback: function () {
                history.push(nextStep);
            },
        }));
    };
    var addressLines = store.address
        ? Object.keys(store.address).filter(function (item) { return /address[0-9]/.test(item); })
        : null;
    return (React.createElement(Card, { classes: "hlcn-card--p-none" },
        showFullDetails && addressLines && (React.createElement(List, { classes: "hlcn-u-pb-medium", items: addressLines.map(function (addressLine) { return ({
                content: React.createElement(Text, { size: "medium" }, store.address[addressLine]),
                classes: 'hlcn-grid--pb-none',
            }); }) })),
        showFullDetails && store.openingHours && (React.createElement("div", { className: "hlcn-u-pb-large", "data-testid": dataTestId.ab.storeDetails.storeCard.openingTimes },
            React.createElement(Text, { as: "p", weight: "bold", classes: "hlcn-u-pb-medium", "data-testid": dataTestId.ab.storeDetails.storeCard.openingTimesTitle }, _t('appointment_booking.steps.store.opening_times_title')),
            React.createElement("ul", null, store.openingHours.map(function (_a) {
                var closes = _a.closes, dayOfWeek = _a.dayOfWeek, opens = _a.opens;
                return (React.createElement("li", { key: dayOfWeek, className: "hlcn-u-align-space-between" },
                    React.createElement(Text, { size: "mediumResponsive", classes: "hlcn-u-is-hidden-max-small" }, getDayOfTheWeekName(dayOfWeek)),
                    React.createElement(Text, { size: "mediumResponsive", classes: "hlcn-u-is-hidden-small" }, getShortDayOfWeekName(dayOfWeek)),
                    React.createElement(Text, { size: "mediumResponsive" },
                        convertTimeToAmPm(opens),
                        " - ",
                        convertTimeToAmPm(closes))));
            })))),
        showStoreLink && (React.createElement("a", { href: createStoreHref(hostUrl, store.sapStoreId), target: "_blank", rel: "noreferrer" },
            React.createElement(Text, { size: "mediumResponsive", weight: "bold", classes: "hlcn-u-link-underline", "data-testid": dataTestId.ab.storeDetails.storeCard.facilitiesLinkText }, _t('appointment_booking.steps.store.store_link_label')))),
        React.createElement(Button, { fullWidth: "full", classes: "hlcn-u-mt-medium", onClick: function () { return onStoreSelect(store.jrniId); }, "data-testid": dataTestId.ab.storeDetails.storeCard.selectStoreButton },
            React.createElement(Text, null, _t('appointment_booking.steps.store.select_button_label')))));
};
export { StoreCard };
