import { allCountries } from 'country-telephone-data';
export var getCountryFromIso2 = function (iso2) {
    return allCountries.find(function (country) { return country.iso2 === iso2; });
};
export var getInitialCountry = function (options) {
    if (options === void 0) { options = {}; }
    var preselectedCountry = options.preselectedCountry, quickCountries = options.quickCountries;
    if (preselectedCountry)
        return getCountryFromIso2(preselectedCountry);
    if (quickCountries === null || quickCountries === void 0 ? void 0 : quickCountries.length)
        return getCountryFromIso2(quickCountries[0]);
    return allCountries[0];
};
