import { createSelector } from 'reselect';
var getRoot = function (state) {
    return state.location.selectedStore;
};
export var getSelectedStoreServiceId = createSelector(getRoot, function (selectedStoreService) { return selectedStoreService.serviceId; });
export var getSelectedStoreId = createSelector(getRoot, function (selectedStoreService) { return selectedStoreService.storeId; });
export var getIsServiceIdLoading = createSelector(getRoot, function (selectedStoreService) { return selectedStoreService.isLoading; });
export var getSelectedStoreErrror = createSelector(getRoot, function (selectedStoreService) { return selectedStoreService.error; });
export var getMinAdvancePeriod = createSelector(getRoot, function (selectedStore) { return selectedStore.minAdvancePeriod; });
export var getMaxAdvancePeriod = createSelector(getRoot, function (selectedStoreService) { return selectedStoreService.maxAdvancePeriod; });
