import { createSelector } from 'reselect';
import { formatAppointmentSummaryTexts } from '../helpers/formatAppointmentSummaryTexts';
import { TIME_SLOT_UNAVAILABLE_ERROR_MSG, ERROR_409_MESSAGE } from './constants';
var getRoot = function (state) {
    return state;
};
export var getCurrentPath = createSelector(getRoot, function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.router) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.pathname; });
export var getIsErrorActiveInDateTime = createSelector(getRoot, function (appointment) {
    var _a, _b, _c, _d, _e;
    var basketError = (_a = appointment === null || appointment === void 0 ? void 0 : appointment.basket) === null || _a === void 0 ? void 0 : _a.error;
    var timeslotDateError = (_c = (_b = appointment === null || appointment === void 0 ? void 0 : appointment.timeslot) === null || _b === void 0 ? void 0 : _b.dateAvailability) === null || _c === void 0 ? void 0 : _c.error;
    var timeslotTimeError = (_e = (_d = appointment === null || appointment === void 0 ? void 0 : appointment.timeslot) === null || _d === void 0 ? void 0 : _d.timeAvailability) === null || _e === void 0 ? void 0 : _e.error;
    return ((!!basketError && (basketError === null || basketError === void 0 ? void 0 : basketError.message) !== ERROR_409_MESSAGE) ||
        !!timeslotDateError ||
        (!!timeslotTimeError &&
            (timeslotTimeError === null || timeslotTimeError === void 0 ? void 0 : timeslotTimeError.message) !== TIME_SLOT_UNAVAILABLE_ERROR_MSG));
});
export var getCollectedData = createSelector(getRoot, function (appointment) {
    var _a, _b, _c, _d, _e, _f;
    return ({
        selectedCategory: (_a = appointment === null || appointment === void 0 ? void 0 : appointment.categories) === null || _a === void 0 ? void 0 : _a.selected,
        selectedStore: (_c = (_b = appointment === null || appointment === void 0 ? void 0 : appointment.location) === null || _b === void 0 ? void 0 : _b.stores) === null || _c === void 0 ? void 0 : _c.active,
        selectedDateTime: (_f = (_e = (_d = appointment === null || appointment === void 0 ? void 0 : appointment.timeslot) === null || _d === void 0 ? void 0 : _d.timeAvailability) === null || _e === void 0 ? void 0 : _e.selected) === null || _f === void 0 ? void 0 : _f.datetime,
    });
});
export var getAppointmentSummary = createSelector(getRoot, function (state) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return formatAppointmentSummaryTexts({
        booking: (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.appointment) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.purchase) === null || _c === void 0 ? void 0 : _c.booking,
        client: (_f = (_e = (_d = state === null || state === void 0 ? void 0 : state.appointment) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.purchase) === null || _f === void 0 ? void 0 : _f.client,
        store: (_h = (_g = state === null || state === void 0 ? void 0 : state.appointment) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.store,
        hostUrl: (_k = (_j = state === null || state === void 0 ? void 0 : state.utils) === null || _j === void 0 ? void 0 : _j.config) === null || _k === void 0 ? void 0 : _k.hostUrl,
    });
});
