import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import classnames from 'classnames';
import { H3, Text, IconAlign, IconCalendar, } from '@realm-kf/halcyon-components-react';
import { _t } from '@hip/translations';
import { dataTestId } from '@hip/test-attributes';
import { getCollectedData } from '../../core/selectors';
import { JrniServiceCategory, getCategory } from '../../core/utils';
import { DropdownCard } from '../dropdownCard/dropdownCard';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import styles from './bookingInfo.css';
var BookingInfo = function () {
    var _a = useSelector(getCollectedData), selectedCategory = _a.selectedCategory, selectedStore = _a.selectedStore, selectedDateTime = _a.selectedDateTime;
    var configCategory = useSelector(getCategory);
    var dateString = dayjs(selectedDateTime).format('dddd D MMMM');
    var timeString = dayjs(selectedDateTime).format('h:mm A');
    var isScreenSizeIsDesktop = useMediaQuery('(min-width: 64em)');
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownCard, { isDropdownDisabled: isScreenSizeIsDesktop, isOpenForcefully: isScreenSizeIsDesktop ? true : null, wrapperClassName: classnames('hlcn-u-bgc-light', !isScreenSizeIsDesktop && 'hlcn-u-mr-medium hlcn-u-ml-medium', styles.BookingInfoWrapper), headerWrapperClassName: classnames(isScreenSizeIsDesktop
                ? 'hlcn-u-p-component-large'
                : 'hlcn-u-p-component', 'hlcn-u-align-center', styles.HeaderWrapper), header: React.createElement(React.Fragment, null,
                React.createElement(IconAlign, { space: "small" },
                    React.createElement(IconCalendar, null),
                    React.createElement(H3, null, _t('appointment_booking.steps.contacts.booking_info.title')))) },
            React.createElement("div", { className: classnames(isScreenSizeIsDesktop
                    ? 'hlcn-u-p-component-large'
                    : 'hlcn-u-p-component') },
                React.createElement("div", { className: "hlcn-u-mb-medium" },
                    React.createElement(Text, { weight: "bold", block: true }, _t('appointment_booking.steps.contacts.booking_info.selected_date_and_time')),
                    React.createElement(Text, { "data-testid": dataTestId.ab.bookingDetails.selectedDate, block: true }, dateString),
                    React.createElement(Text, { "data-testid": dataTestId.ab.bookingDetails.selectedTime, block: true }, timeString)),
                React.createElement("div", { className: "hlcn-u-mb-medium" },
                    React.createElement(Text, { weight: "bold", block: true }, _t('appointment_booking.steps.contacts.booking_info.selected_type')),
                    React.createElement(Text, { "data-testid": dataTestId.ab.bookingDetails.selectedAppointmentType, block: true }, (selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.label) + " " + _t('appointment_booking.steps.contacts.booking_info.selected_type_ending'))),
                configCategory !== JrniServiceCategory.EnergyAssessment && (React.createElement("div", null,
                    React.createElement(Text, { weight: "bold", block: true }, _t('appointment_booking.steps.contacts.booking_info.selected_store')),
                    React.createElement(Text, { "data-testid": dataTestId.ab.bookingDetails.selectedStore, block: true }, selectedStore === null || selectedStore === void 0 ? void 0 : selectedStore.name)))))));
};
export { BookingInfo };
