import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Loading } from '@realm-kf/halcyon-components-react';
import { dataTestId } from '@hip/test-attributes';
import { Header } from './header';
import { Board } from './board';
import { createListOfDaysOfMonth } from './utils';
import styles from './calendar.css';
var Calendar = React.memo(function (_a) {
    var isLoading = _a.isLoading, minDate = _a.minDate, maxDate = _a.maxDate, preselectedDate = _a.preselectedDate, _b = _a.unavailableDates, unavailableDates = _b === void 0 ? [] : _b, onSelectMonth = _a.onSelectMonth, onSelectDate = _a.onSelectDate;
    var _c = useState(dayjs(preselectedDate)), defaultDate = _c[0], setDefaultDate = _c[1];
    useEffect(function () {
        if (preselectedDate) {
            setDefaultDate(dayjs(preselectedDate));
        }
    }, [preselectedDate]);
    var handleSelectPrevMonth = useCallback(function () {
        var prevMonth = defaultDate.subtract(1, 'month');
        setDefaultDate(prevMonth);
        onSelectMonth && onSelectMonth(prevMonth.format('MMYY'));
    }, [defaultDate, onSelectMonth]);
    var handleSelectNextMonth = useCallback(function () {
        var nextMonth = defaultDate.add(1, 'month');
        setDefaultDate(nextMonth);
        onSelectMonth && onSelectMonth(nextMonth.format('MMYY'));
    }, [defaultDate, onSelectMonth]);
    var dates = useMemo(function () { return createListOfDaysOfMonth(defaultDate); }, [
        defaultDate,
    ]);
    var isNextMonthAvailable = !isLoading && dayjs(maxDate).isAfter(defaultDate, 'month');
    var isPrevMonthAvailable = !isLoading && dayjs(minDate).isBefore(defaultDate, 'month');
    return (React.createElement("div", { className: styles.CalendarWrapper, "data-testid": dataTestId.ab.calendar.calendarWidget },
        React.createElement(Header, { title: defaultDate.format('MMMM YYYY'), isNextMonthAvailable: isNextMonthAvailable, isPrevMonthAvailable: isPrevMonthAvailable, onSelectNextMonth: handleSelectNextMonth, onSelectPrevMonth: handleSelectPrevMonth }),
        React.createElement("div", { className: styles.BoardWrapper },
            React.createElement(Loading, { primary: true, show: isLoading }),
            !isLoading && (React.createElement(Board, { dates: dates, maxDate: maxDate, minDate: minDate, preselectedDate: preselectedDate, unavailableDates: unavailableDates, onSelectDate: onSelectDate })))));
});
export { Calendar };
