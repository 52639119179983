import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["LOAD_SERVICE_ID"] = "@hip/appointment-booking/location/selectedStore/LOAD_SERVICE_ID";
    ActionTypes["LOAD_SERVICE_ID_SUCCESS"] = "@hip/appointment-booking/location/selectedStore/LOAD_SERVICE_ID_SUCCESS";
    ActionTypes["LOAD_SERVICE_ID_FAILURE"] = "@hip/appointment-booking/location/selectedStore/LOAD_SERVICE_ID_FAILURE";
    ActionTypes["REMOVE_SERVICE_ID_ERROR"] = "@hip/appointment-booking/location/selectedStore/REMOVE_SERVICE_ID_ERROR";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    loadServiceId: function (payload) { return createAction(ActionTypes.LOAD_SERVICE_ID, payload); },
    loadServiceIdSuccess: function (payload) {
        return createAction(ActionTypes.LOAD_SERVICE_ID_SUCCESS, {
            serviceId: payload.serviceId,
            minAdvancePeriod: payload.minAdvancePeriod,
            maxAdvancePeriod: payload.maxAdvancePeriod,
        });
    },
    loadServiceIdFailure: function (error) {
        return createAction(ActionTypes.LOAD_SERVICE_ID_FAILURE, error);
    },
    removeServiceIdError: function () { return createAction(ActionTypes.REMOVE_SERVICE_ID_ERROR); },
};
