import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["ADD_TO_BASKET"] = "@hip/appointment-booking/basket/ADD_TO_BASKET";
    ActionTypes["ADD_TO_BASKET_SUCCESS"] = "@hip/appointment-booking/basket/ADD_TO_BASKET_SUCCESS";
    ActionTypes["ADD_TO_BASKET_FAILURE"] = "@hip/appointment-booking/basket/ADD_TO_BASKET_FAILURE";
    ActionTypes["REMOVE_FROM_BASKET"] = "@hip/appointment-booking/basket/REMOVE_FROM_BASKET";
    ActionTypes["REMOVE_FROM_BASKET_SUCCESS"] = "@hip/appointment-booking/basket/REMOVE_FROM_BASKET_SUCCESS";
    ActionTypes["REMOVE_FROM_BASKET_FAILURE"] = "@hip/appointment-booking/basket/REMOVE_FROM_BASKET_FAILURE";
    ActionTypes["RESET_BASKET"] = "@hip/appointment-booking/basket/RESET_BASKET";
    ActionTypes["RESET_BASKET_ERROR"] = "@hip/appointment-booking/basket/RESET_BASKET_ERROR";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    addToBasket: function (payload) {
        return createAction(ActionTypes.ADD_TO_BASKET, payload);
    },
    addToBasketSuccess: function (data) {
        return createAction(ActionTypes.ADD_TO_BASKET_SUCCESS, { data: data });
    },
    addToBasketFailure: function (error) {
        return createAction(ActionTypes.ADD_TO_BASKET_FAILURE, { error: error });
    },
    removeFromBasket: function (payload) {
        return createAction(ActionTypes.REMOVE_FROM_BASKET, payload);
    },
    removeFromBasketSuccess: function () {
        return createAction(ActionTypes.REMOVE_FROM_BASKET_SUCCESS);
    },
    removeFromBasketFailure: function (error) {
        return createAction(ActionTypes.REMOVE_FROM_BASKET_FAILURE, { error: error });
    },
    resetBasket: function () { return createAction(ActionTypes.RESET_BASKET); },
    resetBasketError: function () { return createAction(ActionTypes.RESET_BASKET_ERROR); },
};
