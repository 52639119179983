import { createSelector } from 'reselect';
var getRoot = function (state) {
    return state.categories;
};
export var getCategories = createSelector(getRoot, function (categories) { return categories.data; });
export var getCategoryError = createSelector(getRoot, function (categories) { return categories.error; });
export var getIsCategoriesLoading = createSelector(getRoot, function (categories) { return categories.isLoading; });
export var getSelectedCategory = createSelector(getRoot, function (categories) { return categories.selected; });
export var getSelectedCategoryId = createSelector(getSelectedCategory, function (category) { return category === null || category === void 0 ? void 0 : category.key; });
export var getSelectedCategoryGlobalId = createSelector(getSelectedCategory, function (category) { return category === null || category === void 0 ? void 0 : category.globalId; });
