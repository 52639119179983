var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { takeLatest, select, call, put, } from 'redux-saga/effects';
import { getAnalyticsConfig } from '../utils';
import { ActionTypes, actions } from './actions';
import { getIsTealiumReady, getEventQueue } from './selectors';
export function processQueue() {
    var eventQueue, clonedQueue, event_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(getEventQueue)];
            case 1:
                eventQueue = _a.sent();
                clonedQueue = __spreadArrays(eventQueue);
                _a.label = 2;
            case 2:
                if (!clonedQueue.length) return [3 /*break*/, 4];
                event_1 = clonedQueue.shift();
                return [4 /*yield*/, put(actions.sendAnalyticsEvent(event_1))];
            case 3:
                _a.sent();
                return [3 /*break*/, 2];
            case 4: return [4 /*yield*/, put(actions.clearQueue())];
            case 5:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function sendAnalyticsEvent(_a) {
    var type, action, label, category, eventName, isTealiumReady, analyticsConfig, event, _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                type = payload.type, action = payload.action, label = payload.label, category = payload.category, eventName = payload.eventName;
                return [4 /*yield*/, select(getIsTealiumReady)];
            case 1:
                isTealiumReady = _c.sent();
                return [4 /*yield*/, select(getAnalyticsConfig)];
            case 2:
                analyticsConfig = _c.sent();
                event = {
                    tmsAction: action,
                    tmsLabel: label,
                    tmsCategory: category,
                    metaId: analyticsConfig.metaId,
                    eventName: eventName,
                };
                if (!isTealiumReady) return [3 /*break*/, 4];
                return [4 /*yield*/, call(track, type, event)];
            case 3:
                _b = _c.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, put(actions.addToQueue(payload))];
            case 5:
                _b = _c.sent();
                _c.label = 6;
            case 6:
                _b;
                return [2 /*return*/];
        }
    });
}
export function track(type, event) {
    window.utag.track(type, event);
}
export function sagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(ActionTypes.INIT_ANALYTICS, processQueue)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(ActionTypes.SEND_ANALYTICS_EVENT, sendAnalyticsEvent)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
