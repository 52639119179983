import { createSelector } from 'reselect';
import { TIME_SLOT_UNAVAILABLE_ERROR_MSG } from '../../constants';
export var getRoot = function (state) { return state.timeslot.timeAvailability; };
export var getTimeAvailability = createSelector(getRoot, function (timeAvailability) { return timeAvailability.data; });
export var getIsTimeAvailabilityLoading = createSelector(getRoot, function (timeAvailability) { return timeAvailability.isLoading; });
export var getTimeAvailabilityError = createSelector(getRoot, function (timeAvailability) { return timeAvailability.error; });
export var getIsTimeUnavailableError = createSelector(getTimeAvailabilityError, function (error) { return (error === null || error === void 0 ? void 0 : error.message) === TIME_SLOT_UNAVAILABLE_ERROR_MSG; });
export var getSelectedTimeItem = createSelector(getRoot, function (timeAvailability) { return timeAvailability.selected; });
export var getSelectedTimeItemValue = createSelector(getSelectedTimeItem, function (selected) { return selected === null || selected === void 0 ? void 0 : selected.time; });
export var getSelectedDatetimeItemValue = createSelector(getSelectedTimeItem, function (selected) { return selected === null || selected === void 0 ? void 0 : selected.datetime; });
export var getSelectedTimeItemEventId = createSelector(getSelectedTimeItem, function (selected) { return selected === null || selected === void 0 ? void 0 : selected.event_id; });
export var getIsSelectedTimeAvailable = createSelector(getTimeAvailability, getSelectedTimeItem, function (timeAvailabilityData, selectedTimeItem) {
    if (!selectedTimeItem)
        return true;
    var selectedTimeInTimeAvailability = timeAvailabilityData === null || timeAvailabilityData === void 0 ? void 0 : timeAvailabilityData.find(function (time) { return time.datetime === selectedTimeItem.datetime; });
    return Boolean(selectedTimeInTimeAvailability);
});
