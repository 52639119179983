export var processCategories = function (categoriesResponse) {
    var _a, _b;
    return ((_b = (_a = categoriesResponse === null || categoriesResponse === void 0 ? void 0 : categoriesResponse._embedded) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.map(function (item) { return ({
        key: item.id,
        label: item.name,
        globalId: item.global_id,
    }); })) || [];
};
export var processImage = function (imagesResponse) {
    var _a, _b;
    var image = (_b = (_a = imagesResponse === null || imagesResponse === void 0 ? void 0 : imagesResponse._embedded) === null || _a === void 0 ? void 0 : _a.images) === null || _b === void 0 ? void 0 : _b.pop();
    return image === null || image === void 0 ? void 0 : image.url;
};
