import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["LOAD_DATE_AVAILABILITY"] = "@hip/appointment-booking/timeslot/dateAvailability/LOAD_DATE_AVAILABILITY";
    ActionTypes["LOAD_DATE_AVAILABILITY_SUCCESS"] = "@hip/appointment-booking/timeslot/dateAvailability/LOAD_DATE_AVAILABILITY_SUCCESS";
    ActionTypes["LOAD_DATE_AVAILABILITY_FAILURE"] = "@hip/appointment-booking/timeslot/dateAvailability/LOAD_DATE_AVAILABILITY_FAILURE";
    ActionTypes["SELECT_DATE_AVAILABILITY_ITEM"] = "@hip/appointment-booking/timeslot/dateAvailability/SELECT_DATE_AVAILABILITY_ITEM";
    ActionTypes["DESELECT_DATE_AVAILABILITY_ITEM"] = "@hip/appointment-booking/timeslot/dateAvailability/DESELECT_DATE_AVAILABILITY_ITEM";
    ActionTypes["RESET_DATE_AVAILABILITY"] = "@hip/appointment-booking/timeslot/dateAvailability/RESET_DATE_AVAILABILITY";
    ActionTypes["RESET_DATE_AVAILABILITY_ERROR"] = "@hip/appointment-booking/timeslot/dateAvailability/RESET_DATE_AVAILABILITY_ERROR";
    ActionTypes["MARK_DATES_UNAVAILABLE"] = "@hip/appointment-booking/timeslot/dateAvailability/MARK_DATES_UNAVAILABLE";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    loadDateAvailability: function (_a) {
        var selectedMonth = _a.selectedMonth, selectedStoreServiceId = _a.selectedStoreServiceId;
        return createAction(ActionTypes.LOAD_DATE_AVAILABILITY, {
            selectedMonth: selectedMonth,
            selectedStoreServiceId: selectedStoreServiceId,
        });
    },
    loadDateAvailabilitySuccess: function (payload) {
        return createAction(ActionTypes.LOAD_DATE_AVAILABILITY_SUCCESS, payload);
    },
    loadDateAvailabilityFailure: function (error) {
        return createAction(ActionTypes.LOAD_DATE_AVAILABILITY_FAILURE, error);
    },
    selectDateAvailabilityItem: function (date) {
        return createAction(ActionTypes.SELECT_DATE_AVAILABILITY_ITEM, { date: date });
    },
    deselectDateAvailabilityItem: function () {
        return createAction(ActionTypes.DESELECT_DATE_AVAILABILITY_ITEM);
    },
    resetDateAvailability: function () {
        return createAction(ActionTypes.RESET_DATE_AVAILABILITY);
    },
    resetDateAvailabilityError: function () {
        return createAction(ActionTypes.RESET_DATE_AVAILABILITY_ERROR);
    },
    markDatesUnavailable: function (dates) {
        return createAction(ActionTypes.MARK_DATES_UNAVAILABLE, { dates: dates });
    },
};
