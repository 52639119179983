var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { JrniServiceCategory, CategoryName } from '../utils';
export var processPurchaseResponse = function (response) {
    var _a, _b, _c;
    var booking = (_b = (_a = response === null || response === void 0 ? void 0 : response._embedded) === null || _a === void 0 ? void 0 : _a.bookings) === null || _b === void 0 ? void 0 : _b[0];
    if (booking)
        booking = __assign(__assign({}, booking), { category: mapCategoryName(booking.category_name) });
    var client = (_c = response === null || response === void 0 ? void 0 : response._embedded) === null || _c === void 0 ? void 0 : _c.client;
    return { id: response.id, booking: booking, client: client };
};
var mapCategoryName = function (category_name) {
    switch (category_name) {
        case CategoryName.EnergyAssessment:
            return JrniServiceCategory.EnergyAssessment;
        case CategoryName.InStore:
            return JrniServiceCategory.InStore;
        case CategoryName.Online:
            return JrniServiceCategory.Online;
        default:
            return null;
    }
};
