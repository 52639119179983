import { createSelector } from 'reselect';
export var getRoot = function (state) { return state.timeslot.dateAvailability; };
export var getDateAvailability = createSelector(getRoot, function (dateAvailability) { return dateAvailability.data; });
export var getIsDateAvailabilityLoading = createSelector(getRoot, function (dateAvailability) { return dateAvailability.isLoading; });
export var getUnavailableDates = createSelector(getRoot, function (dateAvailability) { return dateAvailability.unavailable; });
export var getSelectedDate = createSelector(getRoot, function (dateAvailability) { return dateAvailability.selected; });
export var getSelectedDateValue = createSelector(getSelectedDate, function (selectedDateItem) { return selectedDateItem === null || selectedDateItem === void 0 ? void 0 : selectedDateItem.date; });
export var getIsSelectedDateAvailable = createSelector(getDateAvailability, getSelectedDate, function (dateAvailability, selectedDate) {
    if (!selectedDate || !dateAvailability)
        return false;
    var selectedDateInDateAvailability = dateAvailability.find(function (day) { return day.date === selectedDate.date; });
    return Boolean(selectedDateInDateAvailability);
});
export var getIsTimeslotToShow = createSelector(getSelectedDateValue, getIsSelectedDateAvailable, getIsDateAvailabilityLoading, function (selectedDateValue, isSelectedDateAvailable, isDateAvailabilityLoading) {
    return Boolean(selectedDateValue && isSelectedDateAvailable && !isDateAvailabilityLoading);
});
