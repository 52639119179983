import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["INIT"] = "@hip/appointment-booking/utils/INIT";
    ActionTypes["SET_CONFIG"] = "@hip/appointment-booking/utils/SET_CONFIG";
    ActionTypes["SET_OPCO"] = "@hip/appointment-booking/utils/SET_OPCO";
    ActionTypes["SET_CATEGORY"] = "@hip/appointment-booking/utils/SET_CATEGORY";
    ActionTypes["SET_APP_READY"] = "@hip/appointment-booking/utils/SET_APP_READY";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    init: function () { return createAction(ActionTypes.INIT); },
    setConfig: function (payload) {
        return createAction(ActionTypes.SET_CONFIG, payload);
    },
    setOPCO: function (payload) { return createAction(ActionTypes.SET_OPCO, payload); },
    setCategory: function (payload) {
        return createAction(ActionTypes.SET_CATEGORY, payload);
    },
    setAppReady: function () { return createAction(ActionTypes.SET_APP_READY); },
};
