import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Text, Button, H3, Loading } from '@realm-kf/halcyon-components-react';
import { _t } from '@hip/translations';
import { Icon } from '@hip/components/src/components/icon/icon';
import { dataTestId } from '@hip/test-attributes';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { actions, getIsLoading } from '../../core/appointment';
import { getAppointmentSummary } from '../../core/selectors';
import { SummaryTable } from './summaryTable';
import { SummaryList } from './summaryList';
import styles from './appointmentSummary.css';
export var AppointmentSummary = function (_a) {
    var appointmentId = _a.appointmentId, isPrintable = _a.isPrintable;
    var dispatch = useDispatch();
    var isScreenSizeIsDesktop = useMediaQuery('(min-width: 45rem)');
    var appointmentSummary = useSelector(getAppointmentSummary);
    var appointmentIsLoading = useSelector(getIsLoading);
    useEffect(function () {
        dispatch(actions.getAppointment(appointmentId));
    }, []);
    var printCurrentPage = function () { return window.print(); };
    if (appointmentIsLoading)
        return React.createElement(Loading, { primary: true, show: true });
    return appointmentSummary ? (React.createElement("div", { className: classnames(styles.SummaryWrapper, 'hlcn-u-ptb-component') },
        React.createElement("div", { "data-testid": dataTestId.ab.confirmationDetails.confirmationHeader, className: classnames(styles.HeaderWrapper, 'hlcn-u-align-space-between', 'hlcn-u-align-space-between--center', 'hlcn-u-p-component', 'hlcn-u-pl-large', 'hlcn-u-pr-large') },
            React.createElement(H3, { "data-testid": dataTestId.ab.confirmationDetails.appointmentType },
                appointmentSummary.serviceName,
                ' ',
                _t('appointment_booking.appointment_summary.appointment')),
            isPrintable && (React.createElement(Button, { small: true, variant: "action", "data-testid": dataTestId.ab.confirmationDetails.printingButton, classes: styles.PrintButton, onClick: printCurrentPage },
                React.createElement(Icon, { icon: "PRINT", size: "s" }),
                React.createElement(Text, { classes: classnames('hlcn-u-pl-xsmall', 'hlcn-u-link-underline', 'hlcn-u-m-none'), space: "small" }, _t('appointment_booking.appointment_summary.print'))))),
        isScreenSizeIsDesktop ? (React.createElement(SummaryTable, { appointmentSummaryTexts: appointmentSummary })) : (React.createElement(SummaryList, { appointmentSummaryTexts: appointmentSummary })))) : null;
};
