var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { TealiumEventType, AnalyticsEvents, ANALYTICS_CATEGORY, } from './interfaces';
export var AnalyticsEventMap = (_a = {},
    _a[AnalyticsEvents.START_BOOKING] = {
        type: TealiumEventType.link,
        action: 'Start booking',
        category: ANALYTICS_CATEGORY,
        eventName: 'appointBookSource',
    },
    _a[AnalyticsEvents.SELECT_SERVICE] = {
        type: TealiumEventType.link,
        action: 'Select Service',
        category: ANALYTICS_CATEGORY,
        eventName: 'appointBookProdCat',
    },
    _a[AnalyticsEvents.SELECT_STORE] = {
        type: TealiumEventType.link,
        action: 'Select Store',
        category: ANALYTICS_CATEGORY,
        eventName: 'appointBookStoreName',
    },
    _a[AnalyticsEvents.DATETIME] = {
        type: TealiumEventType.link,
        action: 'Select Date/Time',
        category: ANALYTICS_CATEGORY,
        eventName: 'appointBookDateTime',
    },
    _a[AnalyticsEvents.DETAILS] = {
        type: TealiumEventType.link,
        action: 'Customer Details',
        category: ANALYTICS_CATEGORY,
        eventName: 'appointBookDetails',
    },
    _a[AnalyticsEvents.CONFIRMATION] = {
        type: TealiumEventType.link,
        action: 'Confirmation',
        category: ANALYTICS_CATEGORY,
        eventName: 'appointbookConfirm',
    },
    _a[AnalyticsEvents.AMEND] = {
        type: TealiumEventType.link,
        action: 'Open Cancel/Amend Page',
        category: ANALYTICS_CATEGORY,
        eventName: 'appointBookUpdate',
    },
    _a[AnalyticsEvents.AMEND_CONFIRMATION] = {
        type: TealiumEventType.link,
        action: 'AB Change Confirmation',
        category: ANALYTICS_CATEGORY,
        eventName: 'appointBookChgConfirm',
    },
    _a[AnalyticsEvents.CANCEL_CONFIRMATION] = {
        type: TealiumEventType.link,
        action: 'Cancellation Confirmation',
        category: ANALYTICS_CATEGORY,
        eventName: 'appointBookCancConfirm',
    },
    _a[AnalyticsEvents.GENERIC_ERROR] = {
        type: TealiumEventType.link,
        action: 'Generic Error',
        category: ANALYTICS_CATEGORY,
        eventName: 'appointBookError',
    },
    _a[AnalyticsEvents.AMEND_GENERIC_ERROR] = {
        type: TealiumEventType.link,
        action: 'Generic Error',
        category: ANALYTICS_CATEGORY,
        eventName: 'appointBookChgError',
    },
    _a);
export var makeAnalyticsPayload = function (event, label) {
    return __assign(__assign({}, AnalyticsEventMap[event]), { label: label });
};
