var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { takeLatest, put, call, select, delay, } from 'redux-saga/effects';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import initTranslations from '@hip/translations';
import { detectedLanguageToDayJsLocale, } from '../../helpers/localesDictionary';
import { jrniAPI } from '../../api/jrniAPI';
import { TIME_TO_SHOW_ERROR_TOAST } from '../constants';
import { actions as utilsActions, ActionTypes as UtilsActionTypes, } from './actions';
import { fetchConfig } from './requests';
import { getJrniAppId, getJrniBaseUrl } from './selectors';
export function init() {
    var _a, opco, config, language, e_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 8, , 9]);
                return [4 /*yield*/, call(fetchConfig)];
            case 1:
                _a = _b.sent(), opco = _a.opco, config = _a.config;
                return [4 /*yield*/, put(utilsActions.setConfig(config))];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(utilsActions.setOPCO(opco))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(initTranslations, opco)];
            case 4:
                language = _b.sent();
                return [4 /*yield*/, call(initJrniAPI)];
            case 5:
                _b.sent();
                return [4 /*yield*/, call(initDayJs, language)];
            case 6:
                _b.sent();
                return [4 /*yield*/, put(utilsActions.setAppReady())];
            case 7:
                _b.sent();
                return [3 /*break*/, 9];
            case 8:
                e_1 = _b.sent();
                // TODO: Handle error https://jira.kfplc.com/browse/HIP-4949
                console.error(e_1);
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function initJrniAPI() {
    var jrniBaseUrl, jrniAppId;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(getJrniBaseUrl)];
            case 1:
                jrniBaseUrl = _a.sent();
                return [4 /*yield*/, select(getJrniAppId)];
            case 2:
                jrniAppId = _a.sent();
                jrniAPI.defaults.baseURL = jrniBaseUrl;
                jrniAPI.defaults.headers['app-id'] = jrniAppId;
                return [2 /*return*/];
        }
    });
}
export function initDayJs(language) {
    return __awaiter(this, void 0, void 0, function () {
        var dayJsLocale;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dayJsLocale = detectedLanguageToDayJsLocale(language);
                    return [4 /*yield*/, import("dayjs/locale/" + dayJsLocale + ".js")];
                case 1:
                    _a.sent();
                    dayjs.locale(dayJsLocale);
                    dayjs.extend(duration);
                    dayjs.extend(relativeTime);
                    dayjs.extend(weekday);
                    dayjs.Ls.en.weekStart = 1;
                    return [2 /*return*/];
            }
        });
    });
}
export function sagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(UtilsActionTypes.INIT, init)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function runActionAfterDelay(removeErrorAction, delayTime) {
    if (delayTime === void 0) { delayTime = TIME_TO_SHOW_ERROR_TOAST; }
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, delay(delayTime)];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(removeErrorAction)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
