import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { dataTestId } from '@hip/test-attributes';
import { TimeSlot } from '../../../components/timeSlot';
import { getIsTimeslotToShow, getSelectedDateValue, } from '../../../core/timeslot/dateAvailability';
import { actions, getTimeAvailability, getIsTimeAvailabilityLoading, getSelectedTimeItem, getIsTimeUnavailableError, } from '../../../core/timeslot/timeAvailability';
import { getBasketErrorStatus } from '../../../core/basket/selectors';
import { actions as basketActions } from '../../../core/basket/actions';
import { getBookingTimeSlot } from '../../../core/appointment';
var TimeSelection = function () {
    var dispatch = useDispatch();
    /**
     * What `isMounted` for?
     * The `useSelector` hook works properly unless a component is re-mounted.
     * Let's review the next situation:
     * <TimeSelection/> mounted and `isTimeslotToShow` is true.
     * Later, the component was unmounted, and some store changes were reduced.
     * Later, the component was mounted again.
     * On the first render after re-mounting, selector `getIsTimeslotToShow` returns false.
     * BUT `useSelector` RETURNS LAST VALUE WHICH WAS BEFORE UNMOUNT, which is true.
     * So `isTimeslotToShow` should be false but it is true.
     * This issue is observed only on the re-mounting of a component.
     * To avoid this but `useState` below is used. :|
     *
     * Check this thread to get more info:
     * https://gitlab.kfplc.com/digitalhub/applications/hip/-/merge_requests/1014#note_1200070
     */
    var _a = useState(false), isMounted = _a[0], setMounted = _a[1];
    var isTimeslotToShow = useSelector(getIsTimeslotToShow);
    var basketErrorStatus = useSelector(getBasketErrorStatus);
    var timeAvailability = useSelector(getTimeAvailability);
    var isTimeAvailabilityLoading = useSelector(getIsTimeAvailabilityLoading);
    var selectedDateValue = useSelector(getSelectedDateValue);
    var selectedTime = useSelector(getSelectedTimeItem);
    var isTimeUnavailableError = useSelector(getIsTimeUnavailableError);
    var bookingTimeSlot = useSelector(getBookingTimeSlot);
    var handleSelectTime = function (time) {
        dispatch(actions.selectTimeAvailabilityItem(time));
        dispatch(basketActions.resetBasketError());
        dispatch(actions.resetTimeAvailabilityError());
    };
    // will run after first render
    useEffect(function () { return setMounted(true); }, []);
    useEffect(function () {
        if (isTimeslotToShow && isMounted) {
            dispatch(actions.loadTimeAvailability());
        }
    }, [isTimeslotToShow, selectedDateValue]);
    useEffect(function () {
        // `bookingTimeSlot` available only on amend journey
        if (bookingTimeSlot) {
            dispatch(actions.selectTimeAvailabilityItem(bookingTimeSlot));
        }
    }, [bookingTimeSlot]);
    return isTimeslotToShow ? (React.createElement(TimeSlot, { dataTestId: dataTestId.ab.calendar.timeSlot, title: dayjs(selectedDateValue).format('dddd D MMMM'), timeAvailability: timeAvailability, selectedTimeSlot: selectedTime, onSelect: handleSelectTime, isLoading: isTimeAvailabilityLoading, isWarningMsgActive: isTimeUnavailableError || basketErrorStatus === 409 })) : null;
};
export { TimeSelection };
