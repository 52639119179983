var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCategory, JrniServiceCategory } from '../core/utils';
import { RouteList } from '../routes';
var defaultBookingSteps = [
    RouteList.CATEGORY,
    RouteList.STORE,
    RouteList.DATETIME,
    RouteList.CONTACTS,
    RouteList.CONFIRMATION,
];
var getBookingSteps = function (category) {
    if (!category)
        return [];
    var bookingSteps = __spreadArrays(defaultBookingSteps);
    if (category === JrniServiceCategory.EnergyAssessment) {
        bookingSteps.splice(defaultBookingSteps.indexOf(RouteList.STORE), 1);
    }
    return bookingSteps;
};
export var useBookingSteps = function () {
    var pathname = useLocation().pathname;
    var category = useSelector(getCategory);
    var bookingSteps = useMemo(function () { return getBookingSteps(category); }, [
        category,
    ]);
    var currentStepIndex = useMemo(function () { return bookingSteps.findIndex(function (route) { return pathname.includes(route); }); }, [bookingSteps, pathname]);
    return useMemo(function () { return ({
        nextStep: bookingSteps[currentStepIndex + 1],
        totalSteps: bookingSteps.length,
        currentStepNumber: currentStepIndex + 1,
    }); }, [pathname, category]);
};
