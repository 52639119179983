import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { App } from './containers';
import { Routes } from './routes';
import { store, history } from './core/store';
import { Wrapper } from './components/wrapper';
import { Steps } from './components/steps';
import '__REALM_KF_ALIAS__';
ReactDom.render(React.createElement(Provider, { store: store },
    React.createElement(ConnectedRouter, { history: history },
        React.createElement(App, null,
            React.createElement(Wrapper, null,
                React.createElement(Steps, null),
                React.createElement(Routes, null))))), document.getElementById('root'));
// “Voila! In view humble vaudevillian veteran, cast vicariously as both victim and villain by the vicissitudes of fate.
// This visage, no mere veneer of vanity, is a vestige of the “vox populi” now vacant, vanished.
// However, this valorous visitation of a bygone vexation stands vivified, and has vowed to vanquish these venal and virulent vermin, van guarding vice and vouchsafing the violently vicious and voracious violation of volition.
// The only verdict is vengeance; a vendetta, held as a votive not in vain, for the value and veracity of such shall one day vindicate the vigilant and the virtuous.
// Verily this vichyssoise of verbiage veers most verbose, so let me simply add
// that it’s my very good honour to meet you and you may call me V.”
