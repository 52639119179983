var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import classnames from 'classnames';
import { Table, Text, Link } from '@realm-kf/halcyon-components-react';
import { dataTestId } from '@hip/test-attributes';
import { _t } from '@hip/translations';
import { JrniServiceCategory } from '../../core/utils';
import styles from './appointmentSummary.css';
var SummaryTable = function (_a) {
    var appointmentSummaryTexts = _a.appointmentSummaryTexts;
    var dateString = appointmentSummaryTexts.dateString, timeString = appointmentSummaryTexts.timeString, durationString = appointmentSummaryTexts.durationString, storeName = appointmentSummaryTexts.storeName, storeHref = appointmentSummaryTexts.storeHref, firstName = appointmentSummaryTexts.firstName, lastName = appointmentSummaryTexts.lastName, email = appointmentSummaryTexts.email, mobile_prefix = appointmentSummaryTexts.mobile_prefix, mobile = appointmentSummaryTexts.mobile, category = appointmentSummaryTexts.category;
    var tableHeaders = __spreadArrays([
        _t('appointment_booking.appointment_summary.fields.date_and_time'),
        _t('appointment_booking.appointment_summary.fields.duration')
    ], (category !== JrniServiceCategory.EnergyAssessment
        ? [_t('appointment_booking.appointment_summary.fields.location')]
        : []), [
        _t('appointment_booking.appointment_summary.fields.your_details'),
    ]);
    var tableData = __spreadArrays([
        [dateString + "\n" + timeString],
        [durationString]
    ], (category !== JrniServiceCategory.EnergyAssessment
        ? [
            [
                React.createElement("div", { key: storeName },
                    React.createElement(Text, { block: true, space: "xsmall", "data-testid": dataTestId.ab.confirmationDetails.selectedStore }, storeName),
                    category !== JrniServiceCategory.Online && (React.createElement(Link, { href: storeHref, linkUnderline: true, inNewTab: true, "data-testid": dataTestId.ab.confirmationDetails.selectedStoreLink }, _t('appointment_booking.appointment_summary.full_store_info_and_facilities')))),
            ],
        ]
        : []), [
        [firstName + " " + lastName + "\n" + email + "\n" + mobile_prefix + mobile],
    ]);
    return (React.createElement("div", { className: classnames(styles.Table, 'hlcn-u-bgc-light'), "data-testid": dataTestId.ab.confirmationDetails.confirmationTableDesktop },
        React.createElement(Table, { accessibilityText: _t('appointment_booking.appointment_summary.table_caption'), heading: tableHeaders, data: tableData, headingLeft: true })));
};
export { SummaryTable };
