import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { _t } from '@hip/translations';
import { Container, MainContent, Toast, Grid, GridItem, Loading, } from '@realm-kf/halcyon-components-react';
import { RouteList } from '../../routes';
import { DateSelection } from '../timeslotStep/dateSelection';
import { TimeSelection } from '../timeslotStep/timeSelection';
import { Layout } from '../../components/layout';
import { getSelectedDateValue } from '../../core/timeslot/dateAvailability';
import { actions as storeActions } from '../../core/location/selectedStore';
import { actions, getBookingId, getStoreId, getServiceId, getAmendError, getBookingTimeSlot, getAmendIsLoading, } from '../../core/appointment';
import { getSelectedTimeItem, getSelectedTimeItemEventId, getSelectedTimeItemValue, getSelectedDatetimeItemValue, } from '../../core/timeslot/timeAvailability';
import { getIsErrorActiveInDateTime } from '../../core/selectors';
import { actions as analyticsActions, AnalyticsEventMap, AnalyticsEvents, makeAnalyticsPayload, } from '../../core/analytics';
import { useHeaderTranslations } from '../../hooks/useHeaderTranslations';
var AmendCalendar = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var appointmentId = useParams().appointmentId;
    var bookingId = useSelector(getBookingId);
    var eventId = useSelector(getSelectedTimeItemEventId);
    var storeId = useSelector(getStoreId);
    var serviceId = useSelector(getServiceId);
    var selectedTimeItem = useSelector(getSelectedTimeItem);
    var selectedTime = useSelector(getSelectedTimeItemValue);
    var selectedDate = useSelector(getSelectedDateValue);
    var selectedDatetimeItemValue = useSelector(getSelectedDatetimeItemValue);
    var isErrorInDateTime = useSelector(getIsErrorActiveInDateTime);
    var isAmendLoading = useSelector(getAmendIsLoading);
    var amendError = useSelector(getAmendError);
    var bookingTimeSlot = useSelector(getBookingTimeSlot);
    var showToast = isErrorInDateTime || Boolean(amendError);
    var _a = useHeaderTranslations(), title = _a.title, subtitle = _a.subtitle;
    useEffect(function () {
        dispatch(storeActions.loadServiceId({
            selectedStoreJrniId: storeId,
            categoryServiceId: serviceId,
        }));
    }, [storeId, serviceId]);
    var handleConfirm = function () {
        var callback = function () {
            return history.push(RouteList.AMEND + "/" + appointmentId + RouteList.SUCCESS);
        };
        dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.DATETIME, selectedTimeItem === null || selectedTimeItem === void 0 ? void 0 : selectedTimeItem.datetime)));
        dispatch(actions.amendAppointment(appointmentId, bookingId, { event_id: eventId, date: selectedDate, time: selectedTime }, selectedTimeItem.datetime, callback));
    };
    var handleCancel = function () {
        history.goBack();
    };
    useEffect(function () {
        if (amendError) {
            dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.AMEND_GENERIC_ERROR, AnalyticsEventMap[AnalyticsEvents.DATETIME].action)));
        }
    }, [amendError]);
    return (React.createElement(Layout, { title: title, subtitle: subtitle, footerProps: {
            primaryLabel: isAmendLoading ? (React.createElement(Loading, { show: true })) : (_t('appointment_booking.footer.confirm')),
            primaryClick: handleConfirm,
            primaryDisabled: !(selectedDate && selectedTime) ||
                (bookingTimeSlot === null || bookingTimeSlot === void 0 ? void 0 : bookingTimeSlot.datetime) === selectedDatetimeItemValue ||
                isAmendLoading,
            secondaryLabel: _t('appointment_booking.footer.back'),
            secondaryClick: handleCancel,
        } },
        React.createElement(MainContent, null,
            React.createElement(Container, null,
                React.createElement(Grid, { responsive: "full-large" },
                    React.createElement(GridItem, { col: 8 },
                        React.createElement(DateSelection, null)),
                    React.createElement(GridItem, { col: 4 },
                        React.createElement(TimeSelection, null))))),
        React.createElement(Toast, { content: _t('appointment_booking.common.sth_went_wrong'), fromTop: "0", isVisible: showToast, variant: "warning" })));
};
export { AmendCalendar };
