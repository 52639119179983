import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { getRoot as getTimeAvailability } from '../timeslot/timeAvailability/selectors';
import { getDateAvailability, getSelectedDateValue, } from '../timeslot/dateAvailability';
var getRoot = function (state) { return state === null || state === void 0 ? void 0 : state.appointment; };
export var getPurchaseId = createSelector(getRoot, function (appointment) { var _a, _b; return (_b = (_a = appointment === null || appointment === void 0 ? void 0 : appointment.data) === null || _a === void 0 ? void 0 : _a.purchase) === null || _b === void 0 ? void 0 : _b.id; });
export var getBooking = createSelector(getRoot, function (appointment) { var _a, _b; return (_b = (_a = appointment === null || appointment === void 0 ? void 0 : appointment.data) === null || _a === void 0 ? void 0 : _a.purchase) === null || _b === void 0 ? void 0 : _b.booking; });
export var getClient = createSelector(getRoot, function (appointment) { var _a, _b; return (_b = (_a = appointment === null || appointment === void 0 ? void 0 : appointment.data) === null || _a === void 0 ? void 0 : _a.purchase) === null || _b === void 0 ? void 0 : _b.client; });
export var getStore = createSelector(getRoot, function (appointment) { var _a; return (_a = appointment === null || appointment === void 0 ? void 0 : appointment.data) === null || _a === void 0 ? void 0 : _a.store; });
export var getStoreId = createSelector(getBooking, function (booking) { return booking.company_id; });
export var getServiceId = createSelector(getBooking, function (booking) { return booking.service_id; });
export var getServiceName = createSelector(getBooking, function (booking) { return booking === null || booking === void 0 ? void 0 : booking.service_name; });
export var getBookingId = createSelector(getBooking, function (booking) { return booking === null || booking === void 0 ? void 0 : booking.id; });
export var getBookingTimeSlot = createSelector(getBooking, getSelectedDateValue, getTimeAvailability, function (booking, selectedDateValue, timeAvailability) {
    var _a;
    if (booking &&
        selectedDateValue === dayjs(booking.datetime).format('YYYY-MM-DD')) {
        return (_a = timeAvailability === null || timeAvailability === void 0 ? void 0 : timeAvailability.data) === null || _a === void 0 ? void 0 : _a.find(function (timeAvailabilityItem) {
            return dayjs(timeAvailabilityItem === null || timeAvailabilityItem === void 0 ? void 0 : timeAvailabilityItem.datetime).isSame(dayjs(booking.datetime));
        });
    }
    return null;
});
// get timeslot from timeslots where date corresponds the date of current booking in the basket
export var getBookingDateSlot = createSelector(getBooking, getDateAvailability, function (booking, dateAvailability) {
    if (!booking)
        return null;
    return dateAvailability === null || dateAvailability === void 0 ? void 0 : dateAvailability.find(function (datetime) { return dayjs(booking.datetime).format('YYYY-MM-DD') === datetime.date; });
});
export var getError = createSelector(getRoot, function (appointment) { return appointment.error; });
export var getErrorType = createSelector(getRoot, function (appointment) { return appointment.errorType; });
export var getCancelError = createSelector(getRoot, function (appointment) { return appointment.cancelError; });
export var getGenericError = createSelector(getError, getCancelError, function (err, cancelErr) { return err || cancelErr; });
export var getIsLoading = createSelector(getRoot, function (appointment) { return appointment.isLoading; });
export var getAmendIsLoading = createSelector(getRoot, function (appointment) { return appointment.amendIsLoading; });
export var getAmendError = createSelector(getRoot, function (appointment) { return appointment.amendError; });
