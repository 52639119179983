import React, { useState } from 'react';
import { Icon } from '@hip/components/src/components/icon/icon';
import { dataTestId } from '@hip/test-attributes';
import styles from './dropdownCard.css';
var DropdownCard = function (_a) {
    var children = _a.children, header = _a.header, dropdownIconElement = _a.dropdownIconElement, dropdownIconName = _a.dropdownIconName, wrapperClassName = _a.wrapperClassName, headerWrapperClassName = _a.headerWrapperClassName, isOpenForcefully = _a.isOpenForcefully, isDropdownDisabled = _a.isDropdownDisabled, onDropdown = _a.onDropdown;
    var _b = useState(isOpenForcefully || false), isOpen = _b[0], setIsOpen = _b[1];
    var handleDropdownClick = function () {
        if (!isDropdownDisabled) {
            setIsOpen(function (state) { return !state; });
        }
        if (onDropdown)
            onDropdown(isOpen);
    };
    return (React.createElement("div", { className: wrapperClassName, "data-testid": dataTestId.ab.bookingDetails.bookingDetailsBar },
        header && (React.createElement("header", { className: headerWrapperClassName, onClick: handleDropdownClick },
            header,
            !isDropdownDisabled && (React.createElement(React.Fragment, null, dropdownIconElement || (React.createElement(Icon, { size: "xs", classes: styles.MarginLeftAuto, icon: (dropdownIconName !== null && dropdownIconName !== void 0 ? dropdownIconName : isOpen) ? 'CHEVRON_UP' : 'CHEVRON_DOWN' })))))),
        (isOpenForcefully !== null && isOpenForcefully !== void 0 ? isOpenForcefully : isOpen) && children));
};
export { DropdownCard };
