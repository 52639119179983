import svgMarker from './assets/marker.svg';
export var initWoosMapScript = function (apiKey, callback) {
    var existingScript = document.getElementById('WoosMap');
    if (!existingScript) {
        var script = document.createElement('script');
        script.src = "https://sdk.woosmap.com/map/map.js?key=" + apiKey + "&callback=initMap";
        script.id = 'WoosMap';
        document.body.appendChild(script);
        script.onload = function () {
            if (callback)
                callback();
        };
    }
    if (existingScript && callback)
        callback();
};
export var cleanupWoosMapScript = function () {
    var existingScript = document.getElementById('WoosMap');
    if (existingScript) {
        document.getElementById('WoosMap').remove();
    }
};
export var mapInitOrUpdateHandler = function (isMapReadyToLoad, isMapAlreadyLoaded, mapRef, markerRef, infoWindowRef, activeStore, mountPoint) {
    if (isMapAlreadyLoaded) {
        updateMap(mapRef, markerRef, infoWindowRef, activeStore);
    }
    else if (isMapReadyToLoad) {
        initMap(mapRef, markerRef, infoWindowRef, activeStore, mountPoint);
    }
};
export var initMap = function (mapRef, markerRef, infoWindowRef, activeStore, mountPoint) {
    if (activeStore === null || activeStore === void 0 ? void 0 : activeStore.address) {
        var _a = activeStore.address, lat = _a.lat, lng = _a.lng;
        var map = new woosmap.map.Map(mountPoint, {
            center: { lat: lat, lng: lng },
            zoom: 15,
        });
        mapRef.current = map;
        markerRef.current = initMarker(mapRef);
        infoWindowRef.current = initInfoWindow(mapRef, markerRef, activeStore);
    }
};
export var updateMap = function (mapRef, markerRef, infoWindowRef, activeStore) {
    if (activeStore === null || activeStore === void 0 ? void 0 : activeStore.address) {
        var map = mapRef.current;
        var marker = markerRef.current;
        var infoWindow = infoWindowRef.current;
        var _a = activeStore === null || activeStore === void 0 ? void 0 : activeStore.address, lat = _a.lat, lng = _a.lng;
        map.setCenter({ lat: lat, lng: lng });
        marker.setMap(null);
        infoWindow.close();
        markerRef.current = initMarker(mapRef);
        infoWindowRef.current = initInfoWindow(mapRef, markerRef, activeStore);
    }
};
var initMarker = function (mapRef) {
    var map = mapRef.current;
    var marker = new woosmap.map.Marker({
        position: map.getCenter(),
        icon: {
            url: svgMarker,
            scaledSize: {
                height: 64,
                width: 46,
            },
        },
    });
    marker.setMap(map);
    return marker;
};
var initInfoWindow = function (mapRef, markerRef, store) {
    var map = mapRef.current;
    var marker = markerRef.current;
    var infoWindow = new woosmap.map.InfoWindow({});
    infoWindow.setContent("<div class=" + 'hlcn-u-p-component-small' + "><h3>" + store.name + "</h3></div>");
    infoWindow.open(map, marker);
    marker.addListener('click', function () {
        infoWindow.open(map, marker);
    });
    return infoWindow;
};
