import React from 'react';
import { Card, Text } from '@realm-kf/halcyon-components-react';
import styles from './categoryButton.css';
var CategoryButton = function (_a) {
    var category = _a.category, onClick = _a.onClick;
    var categoryName = category.label.split(' ').pop();
    return (React.createElement(Card, { classes: styles.CategoryButton, onClick: onClick, testId: categoryName + "-card" },
        React.createElement("div", { className: styles.CategoryButtonIconWrapper },
            React.createElement("img", { className: styles.CategoryButtonIcon, src: category.image, alt: category.label })),
        React.createElement(Text, { classes: styles.CategoryButtonTitle, as: "h2" }, category.label)));
};
export { CategoryButton };
