import { _t } from '@hip/translations';
var NAME_MAX_LENGTH = 40;
var PHONE_MAX_LENGTH = 20;
var EMAIL_MAX_LENGTH = 64;
export var isNotEmpty = function (error) { return function (value) {
    return value === '' ? { isValid: false, error: error } : { isValid: true, error: '' };
}; };
export var isValidName = function (error) { return function (value) {
    var regex = /^(\w|[#&.’\- ])*$/;
    return regex.test(value)
        ? { isValid: true, error: '' }
        : { isValid: false, error: error };
}; };
export var isValidEmail = function (error) { return function (value) {
    var emailRegex = /^(?=.{8,252})[\w]+[.\-+]?((([^.]\.)|([^-]-)|([^+]\+))|([\w])|('))*([\w]|[-])@[A-Za-z\d][-.\w]*[A-Za-z\d]\.([A-Za-z]{2,13})$/;
    return emailRegex.test(value)
        ? { isValid: true, error: '' }
        : { isValid: false, error: error };
}; };
export var isMaxChars = function (error, maxLength) { return function (value) {
    var isValid = value.length <= maxLength;
    return {
        isValid: isValid,
        error: isValid ? '' : error,
    };
}; };
export var isNotInitialZeroNumeric = function (error) { return function (value) {
    var regex = /^[1-9][0-9]*?$/; // Any number not starting with 0
    return regex.test(value) === true
        ? { isValid: true, error: '' }
        : { isValid: false, error: error };
}; };
export var validate = function (value, validators) {
    var map = validators.reduce(function (acc, curr) {
        var validation = curr(value);
        acc.set(validation.isValid, validation);
        return acc;
    }, new Map());
    return map.get(false) ? map.get(false) : map.get(true);
};
export var validateFirstName = function (value) {
    var isEmptyErrorMsg = _t('appointment_booking.steps.contacts.form.fields.firstName.errors.isEmpty');
    var isTooLongErrorMsg = _t('appointment_booking.steps.contacts.form.fields.firstName.errors.isTooLong');
    var containsIllegalCharsErrorMsg = _t('appointment_booking.steps.contacts.form.fields.firstName.errors.containsIllegalChars');
    var trimmedValue = value.trim();
    return validate(trimmedValue, [
        isNotEmpty(isEmptyErrorMsg),
        isMaxChars(isTooLongErrorMsg, NAME_MAX_LENGTH),
        isValidName(containsIllegalCharsErrorMsg),
    ]);
};
export var validateLastName = function (value) {
    var isEmptyErrorMsg = _t('appointment_booking.steps.contacts.form.fields.lastName.errors.isEmpty');
    var isTooLongErrorMsg = _t('appointment_booking.steps.contacts.form.fields.lastName.errors.isTooLong');
    var containsIllegalCharsErrorMsg = _t('appointment_booking.steps.contacts.form.fields.lastName.errors.containsIllegalChars');
    var trimmedValue = value.trim();
    return validate(trimmedValue, [
        isNotEmpty(isEmptyErrorMsg),
        isMaxChars(isTooLongErrorMsg, NAME_MAX_LENGTH),
        isValidName(containsIllegalCharsErrorMsg),
    ]);
};
export var validateIntlPhoneNumber = function (value) {
    var phoneNumber = value.phoneNumber, dialCode = value.country.dialCode;
    var dialCodeEmptyMsg = _t('appointment_booking.steps.contacts.form.fields.intlPhoneNumber.dialCode.errors.isEmpty');
    var dialCodeValidation = validate(dialCode, [isNotEmpty(dialCodeEmptyMsg)]);
    var phoneNumberEmptyMsg = _t('appointment_booking.steps.contacts.form.fields.intlPhoneNumber.phoneNumber.errors.isEmpty');
    var phoneNumberInvalidMsg = _t('appointment_booking.steps.contacts.form.fields.intlPhoneNumber.phoneNumber.errors.isInvalid');
    var isTooLongErrorMsg = _t('appointment_booking.steps.contacts.form.fields.intlPhoneNumber.phoneNumber.errors.isTooLong');
    var phoneNumberValidation = validate(phoneNumber, [
        isNotInitialZeroNumeric(phoneNumberInvalidMsg),
        isNotEmpty(phoneNumberEmptyMsg),
        isMaxChars(isTooLongErrorMsg, PHONE_MAX_LENGTH),
    ]);
    return dialCodeValidation.isValid === false
        ? dialCodeValidation
        : phoneNumberValidation;
};
export var validateEmailAddress = function (value) {
    var isEmptyMsg = _t('appointment_booking.steps.contacts.form.fields.emailAddress.errors.isEmpty');
    var isInvalidMsg = _t('appointment_booking.steps.contacts.form.fields.emailAddress.errors.isInvalid');
    var isTooLongErrorMsg = _t('appointment_booking.steps.contacts.form.fields.emailAddress.errors.isTooLong');
    return validate(value, [
        isValidEmail(isInvalidMsg),
        isNotEmpty(isEmptyMsg),
        isMaxChars(isTooLongErrorMsg, EMAIL_MAX_LENGTH),
    ]);
};
