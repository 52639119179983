import { useEffect, useState, useCallback } from 'react';
var SCRIPT_ID = 'TealiumScript';
var MAX_RETRIES = 2;
export var useAnalytics = function (config, onLoadCallback) {
    var _a = useState(0), retryAttempt = _a[0], setRetryAttempt = _a[1];
    var onError = useCallback(function () {
        removeScriptTag();
        setRetryAttempt(function (retries) { return retries + 1; });
    }, []);
    var onLoad = useCallback(function () {
        var checkTealiumInit = setInterval(function () {
            if (window.utag &&
                window.utag.handler &&
                window.utag.handler.iflag === 1) {
                onLoadCallback();
                clearInterval(checkTealiumInit);
            }
        }, 200);
    }, []);
    useEffect(function () {
        if (config && retryAttempt <= MAX_RETRIES) {
            appendScriptTag(config, onLoad, onError);
        }
    }, [config, onLoad, onError, retryAttempt]);
};
var appendScriptTag = function (config, onLoad, onError) {
    var profile = config.profile, env = config.env;
    var url = "https://tags.tiqcdn.com/utag/kingfisher/" + profile + "/" + env + "/utag.js";
    var script = document.createElement('script');
    script.src = url;
    script.id = SCRIPT_ID;
    script.onload = onLoad;
    script.onerror = onError;
    script.async = true;
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
    window.utag_cfg_ovrd.noview = true;
    document.body.appendChild(script);
};
var removeScriptTag = function () {
    var existingScript = document.getElementById(SCRIPT_ID);
    existingScript && document.body.removeChild(existingScript);
};
