import React, { useMemo } from 'react';
import classnames from 'classnames';
import { dataTestId } from '@hip/test-attributes';
import style from './styles.css';
var Progress = function (_a) {
    var percentage = _a.percentage;
    var widthStyle = useMemo(function () {
        var width = percentage;
        width = width < 0 ? 0 : width;
        width = width > 100 ? 100 : width;
        return { width: width.toFixed() + '%' };
    }, [percentage]);
    return (React.createElement("div", { className: style.Progress, "data-testid": dataTestId.ab.header.stepProgressWidget },
        React.createElement("div", { className: classnames(style.ProgressBar, 'hlcn-u-bgc-brand-primary'), style: widthStyle })));
};
export { Progress };
