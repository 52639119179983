import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["GET_APPOINTMENT_DATA"] = "@hip/appointment-booking/appointment/GET_APPOINTMENT_DATA";
    ActionTypes["GET_APPOINTMENT_DATA_SUCCESS"] = "@hip/appointment-booking/appointment/GET_APPOINTMENT_DATA_SUCCESS";
    ActionTypes["GET_APPOINTMENT_DATA_FAILURE"] = "@hip/appointment-booking/appointment/GET_APPOINTMENT_DATA_FAILURE";
    ActionTypes["CLEAR_ERROR"] = "@hip/appointment-booking/appointment/CLEAR_ERROR";
    ActionTypes["DELETE_APPOINTMENT"] = "@hip/appointment-booking/appointment/DELETE_APPOINTMENT";
    ActionTypes["DELETE_APPOINTMENT_SUCCESS"] = "@hip/appointment-booking/appointment/DELETE_APPOINTMENT_SUCCESS";
    ActionTypes["DELETE_APPOINTMENT_FAILURE"] = "@hip/appointment-booking/appointment/DELETE_APPOINTMENT_FAILURE";
    ActionTypes["CLEAR_DELETE_ERROR"] = "@hip/appointment-booking/appointment/CLEAR_CANCEL_ERROR";
    ActionTypes["AMEND_APPOINTMENT"] = "@hip/appointment-booking/appointment/AMEND_APPOINTMENT";
    ActionTypes["AMEND_APPOINTMENT_SUCCESS"] = "@hip/appointment-booking/appointment/AMEND_APPOINTMENT_SUCCESS";
    ActionTypes["AMEND_APPOINTMENT_FAILURE"] = "@hip/appointment-booking/appointment/AMEND_APPOINTMENT_FAILURE";
    ActionTypes["CLEAR_AMEND_ERROR"] = "@hip/appointment-booking/appointment/CLEAR_AMEND_ERROR";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    getAppointment: function (purchaseId) {
        return createAction(ActionTypes.GET_APPOINTMENT_DATA, { purchaseId: purchaseId });
    },
    getAppointmentSuccess: function (data) {
        return createAction(ActionTypes.GET_APPOINTMENT_DATA_SUCCESS, data);
    },
    getAppointmentFailure: function (error, errorType) {
        return createAction(ActionTypes.GET_APPOINTMENT_DATA_FAILURE, {
            error: error,
            errorType: errorType,
        });
    },
    clearError: function () { return createAction(ActionTypes.CLEAR_ERROR); },
    amendAppointment: function (purchaseId, bookingId, timeslot, datetime, callback) {
        return createAction(ActionTypes.AMEND_APPOINTMENT, {
            purchaseId: purchaseId,
            bookingId: bookingId,
            timeslot: timeslot,
            datetime: datetime,
            callback: callback,
        });
    },
    amendAppointmentSuccess: function () {
        return createAction(ActionTypes.AMEND_APPOINTMENT_SUCCESS);
    },
    amendAppointmentFailure: function (error) {
        return createAction(ActionTypes.AMEND_APPOINTMENT_FAILURE, { error: error });
    },
    clearAmendError: function () { return createAction(ActionTypes.CLEAR_AMEND_ERROR); },
    deleteAppointment: function (purchaseId) {
        return createAction(ActionTypes.DELETE_APPOINTMENT, { purchaseId: purchaseId });
    },
    deleteAppointmentSuccess: function (data) {
        return createAction(ActionTypes.DELETE_APPOINTMENT_SUCCESS, data);
    },
    deleteAppointmentFailure: function (error, errorType) {
        return createAction(ActionTypes.DELETE_APPOINTMENT_FAILURE, { error: error, errorType: errorType });
    },
    clearDeleteError: function () { return createAction(ActionTypes.CLEAR_DELETE_ERROR); },
};
